import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./Header";
import Footer from "./Footer";
import sliderImg from "../image/Screenshot-245.webp";
import imgText from "../image/Video-game-developer-2.webp";
import rummyimg from "../image/Rummy.webp";
import Carrom from "../image/Carrom.webp";
import Teenpatti from "../image/Teen_Patti.webp";
import Roulette from "../image/roulette (1).webp";
import andarbahar from "../image/andarbahar.webp";
import ballpool from "../image/8_ball_pool.webp";
import ludo from "../image/ludo.webp";
import blackjack from "../image/blackjack.webp";
import poker from "../image/poker.webp";
import vegassloticon from "../image/imagesGame/vegas slot.webp";
import tpwaricon from "../image/imagesGame/TP WAR.webp";
import Wingolottery from "../image/Wingolottery.webp";
import teenpattiicon from "../image/imagesGame/Teen patti (1).webp";
import safariOfwealth from "../image/imagesGame/safari of wealth.webp";
import roulette from "../image/imagesGame/Roulette.webp";
import pointsrummyicon from "../image/imagesGame/Points Rummy.webp";
import muflisicon from "../image/imagesGame/Muflis.webp";
import jokericon from "../image/imagesGame/joker.webp";
import fruitsloticon from "../image/imagesGame/Fruit Slot.webp";
import jhandi from "../image/imagesGame/Jhandi Munda (1).webp";
import cricketsloticon from "../image/imagesGame/cricket slot (1).webp";
import andarbaharicon from "../image/imagesGame/Ander Bahar Go.webp";
import ABBaca from "../image/imagesGame/LUDO.webp";
import updownicon from "../image/imagesGame/7 up Down.webp"
import Faq from "./Faq";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";
import slide1 from '../image/lobi1.webp'
import slide2 from '../image/lobi2.webp'
import { SliderGaming } from './SliderGaming';




const Gaming = () => {

  useEffect(() => {
    AOS.init();

  }, [])
  return (
    <>
    
      <WindowTop/>
      <PageLoading type='Gaming' />
      <Header/>
      <section className="gaming-page">
        <section className="my-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-xl-6 slideMain1">
                <div id="carouselExampleInterval" class="carousel slideMain" data-bs-ride="carousel" style={{height:'35vh',width:'100%'}}>
                  <div class="carousel-inner">
                    <div class="carousel-item active" data-bs-interval="1700">
                      <img src={slide1} class="d-block w-100 slideImages" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="1700">
                      <img src={slide2} class="d-block w-100 slideImages" alt="..."/>
                    </div>
                    <div class="carousel-item" data-bs-interval="1700"> 
                      <img src={sliderImg} class="d-block w-100 slideImages" alt="..."/>
                    </div>
                  </div>
              </div>
              </div>
              <div className="col-sm-12 col-xl-6 p-5">
                <h2 className="text-primary">
                  Casino, Board,&nbsp; Slot Game&nbsp; Website and Application
                  100% Solution
                </h2>
                <p>
                  Welcome to Maxway Infotech – Your Trusted IT Partner in
                  Jaipuir,Rajasthan, Serving Clients Across India, the US, And
                  the UK!
                </p>
                <p>
                  <span>
                    Our highly experienced development team utilizes advanced
                    technologies and mobile programming
                  </span>
                  <br />
                  <span>
                    techniques for creating high-resolution, impressive mobile
                    games that run smoothly on the targeted
                  </span>
                  <br />
                  <span>platforms, interfaces, and devices</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid gaming-youtube" data-aos="flip-up">
            <div className="row">
              <div className="col-sm-12 col-xl-4">
                <div>
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/VVDkoOhC7Pk?si=WwM3aoVZhKonkEei"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen=""
                  ></iframe>
                </div>
                <h2>Casino Game</h2>
                <button>Get A Demo</button>
              </div>
              <div className="col-sm-12 col-xl-4">
                <div>
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/VVDkoOhC7Pk?si=WwM3aoVZhKonkEei"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen=""
                  ></iframe>
                </div>
                <h2>Ludo Game</h2>
                <button>Get A Demo</button>
              </div>
              <div className="col-sm-12 col-xl-4">
                <div>
                  <iframe
                    width="100%"
                    height="250"
                    src="https://www.youtube.com/embed/VVDkoOhC7Pk?si=WwM3aoVZhKonkEei"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen=""
                  ></iframe>
                </div>
                <h2>Fantesy Game</h2>
                <button>Get A Demo</button>
              </div>
            </div>
          </div>
        </section>
        <SliderGaming/>
        <section>
          <div className="container-fluid my-5">
            <div className="row" data-aos="fade-left">
              <img src={imgText} alt="" className='img-fluid' />
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="row" data-aos="zoom-in-down">
              <div>
                <h1 className="text-primary text-center my-5">
                  All Game Developments Company
                </h1>
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={rummyimg} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={Carrom} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={Teenpatti} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={Roulette} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={andarbahar} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={ballpool} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={ludo} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={blackjack} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-6 col-xl-4 mb-5">
                <img src={poker} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="my-4">
              <h1 className="text-primary text-center">Our Portfolio</h1>
            </div>
            <div className="row  mb-5" data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="300"
              data-aos-offset="0">
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card1">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={vegassloticon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text" style={{width:'90%'}}>
                      "Heart of Vegas" is a social casino games 2023. Enjoy
                      classic social casino slots! Discover classNameic slot machine
                      games. Buffalo Gold and Dragon link.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                <img src={tpwaricon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh'}}/>
                </div>
                <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}} >
                    <p className="card-text" style={{width:'90%'}}>
                      "Teen Patti War" is a variation of the popular Indian card game Teen Patti, which combines elements of traditional Teen Patti with strategic gameplay and a competitive format.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card1">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={Wingolottery} className="card-img-top" alt="..."  style={{width:'70%',height:'32vh'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      "Bingo" is a widely popular and straightforward game of
                      chance often played for fun and prizes. It’s typically
                      enjoyed in social settings, such as bingo halls, community
                      centers, and online platforms.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0  ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={teenpattiicon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text  cardtxt1" style={{width:'90%'}}>
                    "Teen Patti", also known as “Three Card Poker” or “Flush,” is a popular card game that originated in India. It’s similar to the British card game of “Three Card Brag” and is often played for entertainment and both physical and online casinos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card5 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={safariOfwealth} className="card-img-top" alt="..." style={{width:'70%',height:'30vh'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text  cardtxt1" style={{width:'90%'}}>
                      Welcome come back on our latest bull fighting game in which bull attack on city passenger. Before to the bullfight, the bull is held in a tiny, dark isolation cell. He is not given food or water. Confused and anxious, the bull misses the company of his herd. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={roulette} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1"  style={{width:'90%'}}>
                      "Casino" games are a diverse category of games often associated with gambling and wagering. These games can be found in physical casinos around the world and are also available on various online gambling platforms.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card7  ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={pointsrummyicon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      "Points Rummy" is a popular and fast-paced variant of the traditional Indian card game, Rummy. It’s a game that combines skill, strategy, and a bit of luck. Points Rummy is typically played with two decks of cards, including jokers,
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={muflisicon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      “Mufils” is a popular variation of the card game Teen Patti, also known as Indian Poker. In this variant, the usual ranking of card hands is reversed, making low-value hands more desirable. Here’s
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card7 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={jokericon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      “Joker” is a classic trick-taking card game that is often played with a standard 52-card deck, and sometimes with the addition of one or two joker cards as wild cards. The game can be played by 3 or more players and is known for its strategy and competitive nature
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0  ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={fruitsloticon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      "Fruit-themed slot" games are quite popular in the world of online gambling. These games often incorporate traditional fruit symbols like cherries, lemons, oranges, and melons, along with other classic slot machine symbols such as lucky sevens and bars.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card8 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={jhandi} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt2" style={{width:'90%'}}>
                      “Ludo King” is a popular digital adaptation of the classic board game Ludo. It’s available as a mobile app and can be played on various platforms, including smartphones and tablets. Ludo King allows players to enjoy the traditional Ludo experience with friends and family, both locally and online.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={cricketsloticon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      A “Cricket Slot Game” typically refers to an online or physical slot machine game that features a cricket theme. These games are designed to appeal to cricket fans and often incorporate cricket-related symbols, themes, and imagery.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card9">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={andarbaharicon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      "Andar Bahar" is a popular card game that originated in India. It’s a simple yet engaging betting game often played with a standard deck of cards. Here’s how to play Andar Bahar
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card0 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={ABBaca} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                      "Ludo" is a classic board game that has been enjoyed by people of all ages for generations. It’s a game of strategy, chance, and social interaction. Here’s how to play the traditional version of Ludo:
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-xl-4  mb-4 gaming-protfolio ">
                <div className="card card9 ">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'33vh'}}>
                  <img src={updownicon} className="card-img-top" alt="..." style={{width:'70%',height:'30vh',borderRadius:'15px'}}/>
                  </div>
                  <div className="card-body" style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15vh'}}>
                    <p className="card-text cardtxt1" style={{width:'90%'}}>
                    The "7 Up Down" game is a simple dice game typically played with two dice, often in social settings like parties or gatherings. It's a game of chance and luck, and it's easy to learn and play.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Faq />
      </section>
      <Footer />
      <GotoTop />
      <WhatsappFixed/>
    </>
  );
};

export default Gaming;
