import React, { useState } from "react";
import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";

function Counter({ className, ...rest }) {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return (
        <>
            <section className="counter">
                <div className="title">
                    <h1>our achievements</h1>
                </div>
                <div className="counter-row">
                    <div className="counter-column">
                        <strong data-number="3500">
                            <CountUp {...rest} start={viewPortEntered ? null : 0} end={3500}>
                                {({ countUpRef }) => {
                                    return (
                                        <ReactVisibilitySensor
                                            active={!viewPortEntered}
                                            onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setViewPortEntered(true);
                                                }
                                            }}
                                            delayedCall
                                        >
                                            <span className="number" ref={countUpRef} />
                                        </ReactVisibilitySensor>
                                    );
                                }}
                            </CountUp>
                        </strong>
                        <div className="icon11">
                        <i className="fa-solid fa-plus"></i>
                        </div>
                        <div>
                            HAPPY CLIENTS
                        </div>
                    </div>

                    <div className="counter-column">
                        <strong data-number="10">
                            <CountUp {...rest} start={viewPortEntered ? null : 0} end={10}>
                                {({ countUpRef }) => {
                                    return (
                                        <ReactVisibilitySensor
                                            active={!viewPortEntered}
                                            onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setViewPortEntered(true);
                                                }
                                            }}
                                            delayedCall
                                        >
                                            <span className="number" ref={countUpRef} />
                                        </ReactVisibilitySensor>
                                    );
                                }}
                            </CountUp>
                        </strong>
                        <div>
                            YEARS OF EXPERIENCE
                        </div>
                        <div className="icon1">
                        <i className="fa-solid fa-plus"></i>
                        </div>
                    </div>

                    <div className="counter-column">
                        <strong data-number="23">
                            <CountUp {...rest} start={viewPortEntered ? null : 0} end={23}>
                                {({ countUpRef }) => {
                                    return (
                                        <ReactVisibilitySensor
                                            active={!viewPortEntered}
                                            onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setViewPortEntered(true);
                                                }
                                            }}
                                            delayedCall
                                        >
                                            <span className="number" ref={countUpRef} />
                                        </ReactVisibilitySensor>
                                    );
                                }}
                            </CountUp>
                        </strong>
                        <div>
                            CONFERENCE
                        </div>
                        <div className="icon1">
                        <i className="fa-solid fa-plus"></i>
                        </div>
                    </div>

                    <div className="counter-column">
                        <strong data-number="3200">
                            <CountUp {...rest} start={viewPortEntered ? null : 0} end={3200}>
                                {({ countUpRef }) => {
                                    return (
                                        <ReactVisibilitySensor
                                            active={!viewPortEntered}
                                            onChange={(isVisible) => {
                                                if (isVisible) {
                                                    setViewPortEntered(true);
                                                }
                                            }}
                                            delayedCall
                                        >
                                            <span className="number" ref={countUpRef} />
                                        </ReactVisibilitySensor>
                                    );
                                }}
                            </CountUp>
                        </strong>
                        <div>
                            FINISHED PROJECTS
                        </div>
                        <div className="icon11">
                        <i className="fa-solid fa-plus"></i>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Counter;