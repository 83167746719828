import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import bulkImg from "../image/Screenshot-2023-09-26-152709.webp";
import bulkImg1 from "../image/caht.webp";
import bulkImg2 from "../image/How-to-get-WhatsApp-Cloud-API-1-1-.webp";
import bulkImg3 from "../image/Screenshot-2023-09-26-135814 (1).webp";
import bulkImg4 from "../image/Screenshot-2023-09-25-143411 (1) (1).webp";
import bulkImg5 from "../image/Screenshot-2023-09-25-143552.webp";
import bulkImg6 from "../image/Screenshot-2023-09-25-143218.webp";
import bulkImg8 from "../image/4067833.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";


const BulkWhatsapp = () => {
  return (
    <>
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='bulkWhatsapp' />

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xl-6">
                <img src={bulkImg} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6 ">
                <h2 className="fs-1">Free Demo Available of All Software</h2>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">START DEMO</Link></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <img src={bulkImg1} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <h2 className=" text-light">
                  WELCOME TO BULK WHATSAPP SOFTWARE BY Maxway Infotech
                </h2>
                <p className="text-light">
                  <span>
                    Bulk WhatsApp Marketing Software With an In-Built Google Map
                    Extractor allows users to send text, images, videos, pdf,
                    documents, etc. Here, the user can find other various tools
                    to verify and filter mobile numbers. Groups Contacts Grabber
                    tool allows extracting all contacts from WhatsApp groups.
                  </span>
                </p>
                <p className="text-light">
                  <span>
                    Bulk WhatsApp sender software anti-blocking tool reduces the
                    chance to get blocked. This software is 100% safe and
                    reliable and easy to use. Use WhatsApp bulk message sender
                    to keep your account 100% secure.
                  </span>
                </p>
                <p className="text-light">
                  <span>
                    All users can create and maintain WhatsApp marketing
                    campaigns and also control speed, a delay time between
                    messages, and also user can control sleep time.
                  </span>
                </p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">START NOW</Link></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <h2 className="text-center my-4">Whatsapp Marketing Tool</h2>
          <div className="row">
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-desktop"></i>
              </div>
              <h3 className="mb-5">Desktop based</h3>
            </div>
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-cloud-arrow-up"></i>
              </div>
              <h3 className="mb-5">Cloud based</h3>
            </div>
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-mobile-screen-button"></i>
              </div>
              <h3 className="mb-5">Android app</h3>
            </div>
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-location-arrow"></i>
              </div>
              <h3 className="mb-5">Wapp API</h3>
            </div>
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-robot"></i>
              </div>
              <h3 className="mb-5">Auto Chat Bot</h3>
            </div>
            <div className="col-sm-12 col-lg-2 text-center">
              <div className="bulkIcon">
                <i className="fa-solid fa-users"></i>
              </div>
              <h3 className="mb-5">Auto Wapp Groups</h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page1">
          <div className="container">
            <div className="row pb-5">
              <h2 className="text-center my-4">WhatsApp Cloud Buttons API</h2>
              <div className="col-sm-12 col-xl-6">
                <img src={bulkImg2} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <div className="bulkPta">What Is WhatsApp Cloud API?</div>
                <p className="bulkPargraph">
                  It’s the cloud version of Facebook’s WhatsApp Business API,
                  released in 2019.
                </p>
                <p className="bulkPargraph">
                  Though it has many features that attract the business to start
                  with it, it prevailed with some restrictions for businesses
                  that stopped its widespread adoption by small and medium-level
                  businesses.
                </p>
                <p className="bulkPargraph">
                  The cloud-based API for WhatsApp Business makes it possible
                  for all businesses across the globe to get started on WhatsApp
                  Business
                </p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">START NOW</Link></button>
              </div>
              <div className="col-12">
                <p>
                  Registering with WhatsApp business API was a lengthy procedure
                  and needed to enrol with the BSPs. Now it’s not the case. Any
                  business or developer can seamlessly access WhatsApp business
                  APIs, customize them according to their need, and boost their
                  response time to customers using secure cloud-hosted WhatsApp
                  API.
                </p>
                <p>
                  Facebook (also known as Meta) will now carry for all hosting
                  and servers. Businesses can now quickly register and arrange
                  their WhatsApp business API account free of cost.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  Send Unlimited Bulk Whatsapp Button Msg &amp; API Integration
                  with Beta login (No need to Open Mobile Whatsapp)
                  <br />
                  Now Promotion – Non Stop
                </p>
                <p>
                  API Integration With Any Software
                  <br />
                  API Work 24×7 Hours
                  <br />
                  No Numbers Banned Issue
                  <br />
                  Unlimited Massages Send
                  <br />
                  With Attractive Button Features
                  <br />
                  International WhatsApp Support
                  <br />
                  Auto Reply Chatbot
                  <br />
                  Auto Responder
                  <br />
                  No Pc/Laptop and phone Required
                  <br />
                  Multiple Numbers Scan
                  <br />
                  Multiple Login
                  <br />
                  ➖➖➖➖➖➖➖➖➖
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={bulkImg3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page">
          <div className="container">
            <div className="row">
              <h2 className="text-light text-center my-4">
                Maxway Bulk WhatsApp Web Panel International
              </h2>
              <div className="col-sm-12 col-xl-6 mb-4">
                <p className="text-light">
                  We all know how popular WhatsApp is Internationally. Almost
                  everyone who uses smartphone is using WhatsApp. And people
                  check whatsapp messages more frequently then any other
                  application in mobile. So why not you use this platform to
                  promote and grow your business internationally.
                </p>
                <p className="text-light">
                  We offer ready to use web-based whatsapp marketing service
                  internationally and have a large client based over the
                  international market who send messages via whatsapp on daily
                  basis and get 100% delivery of their messages and promote
                  their business. Your whatsapp messages would be broadcasted
                  immediately and you can also view campaign report online{" "}
                </p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">START NOW</Link></button>
              </div>
              <div className="col-sm-12 col-xl-6 mb-4">
                <img src={bulkImg4} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6 mb-4">
                <img src={bulkImg5} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6 mb-4">
                <p className="text-light">
                  – With every number Country Code is Mandatory
                  <br />
                  Campaign delivery within 3-4 hours.
                  <br />
                  All WhatsApp Messages will be delivered through Virtual
                  Numbers.
                  <br />
                  You Can Attach Text Characters, 4 photos, 1 video, 1 PDF in 1
                  What’s App Credit.
                  <br />
                  100% delivery
                  <br />
                  Get Detailed Reports after 24 hours of Campaigns sent.
                  <br />
                  Get refund of NON-Whatsapp in 48 hours Of campaign sent
                  <br />
                  Campaign Timing – 9 AM to 6 PM (Monday-Saturday)
                  <br />
                  ➖➖➖➖➖➖➖➖➖
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page1">
          <div className="container">
            <div className="row">
              <h2 className="text-center my-4">
                Maxway Bulk Whatsapp Web Panel Button For Domestic (India)
              </h2>
              <div className="col-sm-12 col-xl-6">
                <img src={bulkImg6} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  We have existing business relations with major of the
                  companies in each and every state across India Level which
                  will ensure a hassle free &amp; seamless service experience.
                  Strong &amp; efficient development team which will deliver
                  continuous innovation and customer satisfaction to a whole new
                  level. Bulk WhatsApp Services is an easy way to promote
                  business using WhatsApp technology.
                </p>
                <p></p>
                <p>
                  We provide large amount of WhatsApp facility to our
                  customer’s. You can easily deliver messages to a large
                  audience hassle free and can promote your business Nationwide.{" "}
                </p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">START NOW</Link></button>
              </div>
              <div className="col-sm-12 col-xl-6 pb-5">
                <p><span>Send Bulk Whatsapp Msg with Call to Action Button for Direct clickable link to your Large Numbers customers for Promote your Business._</span><br />
                  <span>✔️ Msg Send via Random Virtual Mobile No.</span><br />
                  <span>✔️ No any Accounts Block/Banned issues.</span><br />
                  <span>✔️ Support Call to Action Button.</span><br />
                  <span>✔️ Send Text, Images, Video and pdf in a Credit.</span><br />
                  <span>✔️ Non-Whatsapp Numbers Credit Refund.</span><br />
                  <span> 100% Delivery within 3 to 4 Hours.</span><br />
                  <span> Msg delivered only Working Day between 9am to 7 pm.</span><br />
                  <span>❌ Spam/Fraud/illegal Msg Not Allowed in Any case.</span></p>
              </div>
              <div className="col-sm-12 col-xl-6 pb-5">
                <img src={bulkImg4} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page2">
          <div className="container">
            <h2 className="text-center my-4">Maxway Latest WhatsApp Sender With buttons</h2>
            <div className="row">
              <div className="col-sm-12 col-xl-6 mb-5">
                <img src={bulkImg3} alt="" className="img-fluid" />

              </div>
              <div className="col-sm-12 col-xl-6 mb-5">
                <h3>Software Features :</h3>
                <p>1. Easy To Use<br />
                  2. Create And Upload Database of your potential customers<br />
                  3. Send Text, Image, Video (Make Viral)<br />
                  4. 100% Delivery On Number<br />
                  5. In-Built Filter<br />
                  6. Get Instant Report<br />
                  7. No Credit limit, Send Unlimited msg to Unlimited Users And Clients<br />
                  8. Lowest Rate In Market<br />
                  9. Customized, Transparency, And Control<br />
                  10. It’s Easy From Bulk Sms<br />
                  11. Also, Opening rate in WA msg is more than bulk SMS<br />
                  12.Account Switching in Running Campaign<br />
                  13.Sending Customized Message</p>
              </div>
              <div className="col-sm-12 col-xl-6 mb-5">
                <p><strong>Maxway Latest WhatsApp Sender With buttons</strong></p>
                <p>New Whatsapp Marketing Windows Pc Software with Button Msg._<br />
                  ✅ Send Unlimited Whatsapp Massage.<br />
                  ✅ Call to Action Button.<br />
                  ✅ Fast Whatsapp Number Filter.<br />
                  ✅ Group Contact Grabber.<br />
                  ✅ Send Text,Video,Pdf,Image’s and Etc<br />
                  ✅ Anti-Banned tools &amp; Trick.<br />
                  ✅ Multi Wa-Account Rotation.<br />
                  ✅ Normal Sending speed for reduce Blocking.<br />
                  ✅ 100% Original Product<br />
                  ✅ 100% Best Service &amp; Support.</p>
              </div>
              <div className="col-sm-12 col-xl-6 mb-5">
                <img src={bulkImg8} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulkwhatsapp-page mb-4">
          <div className="container">
            <h2 className="text-center fs-1 text-light my-5 mx-2">Pricing & Offers</h2>
            <div className="row d-flex justify-content-around my-4  ">
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fa-solid fa-mobile-screen-button"></i></h4>
                <h5>Bulk WhatsApp Web Panel <br /> International</h5>
                <h6>₹ <span>20</span> 000</h6>
                <h3 className="text-secondary fs-4">- With every number Country<br /> Code is Mandatory</h3>
                <p>100K Msg Rs.20,000 ,<br /></p>
                <p>✅Campaign delivery within 3-4 hours.<br />
                  ✅All WhatsApp Messages will be <br /> delivered through Virtual Numbers.<br />
                  ✅You Can Attach Text Characters, 4 <br /> photos, 1 video, 1 PDF in 1 What’s App Credit.<br />
                  ✅100% delivery<br />
                  ✅Get Detailed Reports after 24 <br /> hours of Campaigns sent.<br />
                  ✅Get refund of NON-Whatsapp in <br /> 48 hours Of campaign sent<br />
                  ✅Campaign Timing – 9 AM to 6 PM <br /> (Monday-Saturday)</p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW </Link></button>
              </div>
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fa-brands fa-whatsapp"></i></h4>
                <h5>Bulk WhatsApp Web Panel <br /> DOMESTIC</h5>
                <h6>₹ <span>12</span> 000</h6>
                <h3 className="text-secondary fs-4">SEND BULK WHATSAPP MSG TO<br />YOUR LARGE NUMBERS<br />CUSTOMERS FOR PROMOTE YOUR BUSINESS</h3>
                <p>100K Msg Rs.12,000 ,<br /></p>
                <p>✔ Msg Send via Random Virtual<br /> Mobile No.</p>
                <p>✔ No any Accounts Block/Banned<br /> issues.<br />
                  ✔ Send Text, Images, Video and pdf<br /> in a Credit.<br />
                  ✔ Non-Whatsapp Numbers Credit<br /> Refund.<br />
                  100% Delivery within 3 – 4 Hours.<br />
                  Working Hours would between 9AM<br /> to 6 PM.<br />
                  ❌ Spam/Fraud/illegal Msg Not<br /> Allowed in Any case.</p>

                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW</Link>  </button>
              </div>
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fa-solid fa-cloud-arrow-up"></i></h4>
                <h5>MAXWAY LATEST WHATSAPP <br />SENDER</h5>
                <h6>₹ <span>4</span> 500</h6>
                <h3 className="text-secondary fs-4">New Whatsapp Marketing<br /> Windows Pc Software with<br /> Button Msg</h3>
                <p>Single Pc ₹4500 for 1 Year.<br /></p>
                <p>✅ Send Unlimited Whatsapp<br /> Massage.<br />
                  ✅ Fast Whatsapp Number Filter.<br />
                  ✅ Group Contact Grabber.<br />
                  ✅ Send Text,Video,Pdf,Image’s and<br /> Etc<br />
                  ✅ Anti-Banned tools &amp; Trick.<br />
                  ✅ Multi Wa-Account Rotation.<br />
                  ✅ Normal Sending speed for reduce<br /> Blocking.<br />
                  ✅ 100% Original Product<br />
                  ✅ 100% Best Service &amp; Support.</p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW</Link></button>
              </div>
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fa-solid fa-cart-arrow-down"></i></h4>
                <h5>BASIC PLAN</h5>
                <h6>₹ <span>6</span> 000</h6>
                <p>Add 5 WhatsApp Accounts<br />
                  1,00,000 Messages Limit Per Month<br />
                  API Integration With Any Software<br />
                  API Work 24×7 Hours<br />
                  No Numbers Banned Issue<br />
                  Unlimited Massages Send<br />
                  With Attractive Button Features<br />
                  International WhatsApp Support<br />
                  Auto Reply Chatbot<br />
                  Auto Responder<br />
                  No Pc/Laptop and phone Required<br />
                  Multiple Numbers Scan<br />
                  Multiple Login
                </p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW</Link> </button>
              </div>
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fa-solid fa-maximize"></i></h4>
                <h5>STANDARD PLAN</h5>
                <h6>₹ <span>8</span> 000</h6>
                <p>Add 10 WhatsApp Accounts<br />
                  2,00,000 Messages Limit Per Month<br />
                  API Integration With Any Software<br />
                  API Work 24×7 Hours<br />
                  No Numbers Banned Issue<br />
                  Unlimited Massages Send<br />
                  With Attractive Button Features<br />
                  International WhatsApp Support<br />
                  Auto Reply Chatbot<br />
                  Auto Responder<br />
                  No Pc/Laptop and phone Required<br />
                  Multiple Numbers Scan<br />
                  Multiple Login</p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW</Link> </button>
              </div>
              <div className="col-sm-12 col-xl-4 bulk-colo ">
                <h4 className="fs-1 my-5"><i className="fas fa-rss-square"></i></h4>
                <h5>PREMIUM PLAN</h5>
                <h6>₹ <span>12</span> 000</h6>
                <p>Add 5 WhatsApp Accounts<br />
                  1,00,000 Messages Limit Per Month<br />
                  API Integration With Any Software<br />
                  API Work 24×7 Hours<br />
                  No Numbers Banned Issue<br />
                  Unlimited Massages Send<br />
                  With Attractive Button Features<br />
                  International WhatsApp Support<br />
                  Auto Reply Chatbot<br />
                  Auto Responder<br />
                  No Pc/Laptop and phone Required<br />
                  Multiple Numbers Scan<br />
                  Multiple Login</p>
                <button className="bulkWh"><Link className="hLink" to="/whatsapp">BUY NOW</Link> </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsappFixed />
    </>
  );
};

export default BulkWhatsapp;
