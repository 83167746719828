import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import colorpic from "../image/Video-game-developer-1 (1).webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";

const ColorGame = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='Color Prediction game' />

      <section>
        <div className="container-fluid">
          <div className="container color-page">
            <h1 className="text-center my-5 py-5">Color Prediction Games</h1>
            <div className="row">
              <div className="col-12">
                <img src={colorpic} alt="" className="img-fluid py-3" />
              </div>
              <div className="col-12">
                <p className="my-4 fs-4">
                  <strong>Description:</strong>
                </p>
                <p>
                  The Color Palette Game is an engaging and creative game that
                  challenges players to test their color knowledge and artistic
                  skills. It’s a fun way to explore the world of colors and
                  unleash your inner artist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container color-section">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <div>
                  <button>GET A DEMO USER</button>
                </div>
                <h4 className="mb-4">User Id - 1234567890</h4>
                <h4 className="my-4">Password - 123456</h4>
              </div>
              <div className="col-sm-12 col-xl-6">
                <div>
                  <button>GET A DEMO ADMIN</button>
                </div>
                <h4 className="mb-4">Admin Id - admin</h4>
                <h4 className="my-4">Password - 123456</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container color-page py-5">
            <div className="row">
              <div className="col-11 px-4">
                <p>&nbsp;</p>
                <p>
                  <strong>How to Play:</strong>
                </p>
                <ul>
                  <li>
                    <strong>Setup</strong>: Gather materials, including colored
                    pencils, markers, or paints, along with blank sheets of
                    paper. You can also play digitally using graphic design
                    software or apps if preferred.
                  </li>
                  <li>
                    <strong>Objective</strong>: The goal of the game is to
                    recreate specific color palettes or create your own unique
                    color combinations.
                  </li>
                  <li>
                    <strong>Palette Selection</strong>: Decide whether you want
                    to use pre-defined color palettes or create your own.
                    Pre-defined palettes can be found online or in color
                    inspiration books. Alternatively, you can generate random
                    color combinations using online tools or apps.
                  </li>
                  <li>
                    <strong>Time Limit (Optional)</strong>: To add an element of
                    challenge, you can set a time limit for each round. For
                    example, players might have 5-10 minutes to complete their
                    artwork.
                  </li>
                  <li>
                    <strong>Artwork Creation</strong>: Each player or team
                    selects a color palette and starts creating artwork using
                    only the colors from the chosen palette. The artwork can be
                    anything – abstract, a landscape, a portrait, or even a
                    pattern.
                  </li>
                  <li>
                    <strong>Judging</strong>: After the allotted time (if using
                    a timer), players or teams present their creations.
                    Participants can take turns explaining their inspiration and
                    the techniques they used.
                  </li>
                  <li>
                    <strong>Scoring (Optional)</strong>: If you want to make it
                    competitive, you can have a judging panel or the other
                    players vote on the best artwork based on creativity, use of
                    colors, and overall aesthetic appeal. You can assign scores
                    or simply choose a winner for each round.
                  </li>
                  <li>
                    <strong>Repeat</strong>: Continue playing multiple rounds,
                    experimenting with different color palettes and themes.
                  </li>
                </ul>
                <p>
                  <strong>Variations:</strong>
                </p>
                <ul>
                  <li>
                    <strong>Blind Palette</strong>: In this variation, players
                    are given a color palette without seeing the actual colors.
                    They must then create artwork based solely on the names or
                    descriptions of the colors.
                  </li>
                  <li>
                    <strong>Team Play</strong>: Divide players into teams and
                    have them collaborate on creating artwork using the chosen
                    palette.
                  </li>
                  <li>
                    <strong>Digital vs. Traditional</strong>: Players can choose
                    between traditional art supplies or digital tools to create
                    their artwork.
                  </li>
                  <li>
                    <strong>Themed Rounds</strong>: Add a thematic element by
                    choosing color palettes that represent emotions, seasons, or
                    specific events.
                  </li>
                </ul>
                <p>
                  The Color Palette Game is a versatile and imaginative activity
                  that can be enjoyed by artists and non-artists alike. It’s a
                  great way to foster creativity, learn about color theory, and
                  have fun with friends or family. So, gather your colors,
                  unleash your creativity, and start exploring the world of
                  color palettes!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ColorGame;
