import React from "react";
import Header from "./Header";
import FantesyFaq from "./FantesyFaq";
import Footer from "./Footer";
import fantesy from "../image/fantasy-cricket-slider.webp";
import fantesy1 from "../image/fantasy_sports-300x247.webp";
import fantesy2 from "../image/blogimageA-Comprehensive-Guide-to-Developing-Multi-Sport-Fantasy-Apps (1).webp";
import fantesy3 from "../image/blogimageThe-Ultimate-Guide-to-Fantasy-Cricket-App-Development.webp";
import fantesy4 from "../image/fantasy_g-2048x883.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";


const FantesyGame = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='Fantasy game development' />

      <section>
        <div className="container-fluid fantesy-page">
          <div className="container">
            <h1 className="text-center my-5 py-3">Fantasy Game Development</h1>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container-fluid fantesy-page">
          <div className="container">
            <div className="row pt-2">
              <div className="col-sm-12 col-xl-6">
                <img src={fantesy} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <h3>
                  {" "}
                  <span>
                    ⚽ Fantasy Game Website and Application 100% Solutions⚽
                  </span>
                </h3>
                <p>&nbsp;</p>
                <p>
                  Welcome to Maxway Infotech - Your Trusted IT Partner in
                  Jaipur, Rajasthan, Serving Clients Across India, the US, and
                  the UK!
                </p>
                <p>
                  In the world of online game and sports enthusiasts, fantasy
                  cricket has emerged as a true game-changer. With the allure
                  of&nbsp; winnings and the excitement of being a virtual team
                  manager, it’s no wonder that fantasy cricket has taken the
                  internet by storm. Here at Maxway Infotech , we’re diving deep
                  into the world of fantasy cricket, exploring its evolution,
                  strategies, and why it’s the ultimate&nbsp; game for cricket
                  aficionados.
                </p>
              </div>
              <h1 className="text-center py-5 mt-5">
                Admin Panel And Mobile App Features
              </h1>
              <div className="col-sm-12 col-xl-6 mt-2 pe-2">
                <p>
                  <strong>⚽ADMIN PANEL⚽</strong>
                </p>
                <p>
                  Fantasy admin panel
                  <br />
                  Admin Login
                  <br />
                  We have a secured login with verification.
                </p>
                <p>
                  <strong>Match Management</strong>
                  <br />
                  Admin can add matches as per their choice, Update match
                  details, activate and deactivate match.)
                </p>
                <p>
                  <strong>Admin Dashboard</strong>
                  <br />
                  Admin have all the reports in dashboard like Current live
                  match, Total contest, Total winning amount, Total Withdrawal ,
                  Total Users etc
                </p>
                <p>
                  <strong>Revenue management</strong>
                  <br />
                  Admin can check report all the revenue generated from either
                  add cash or contests, User can check their profit match wise,
                  Contest wise and series wise also.
                </p>
                <p>
                  <strong>Withdrawal request management</strong>
                  <br />
                  Admin can set withdrawal minimum amount, get list of all
                  winning request and settled in single click.
                </p>
                <p>
                  <strong>Cash bonus management</strong>
                  <br />
                  Admin can set bonus amount on User signup, user referral and
                  joining contests.
                </p>
                <p>
                  <strong>Contest management</strong>
                  <br />
                  Admin can create their desired contest, set winning amount
                  either through percentage or numeric value, we have already
                  created readymade contest.
                </p>
                <p>
                  <strong>Match Settlement</strong>
                  <br />
                  Admin can settled matches on single click and see all match
                  reports.
                </p>
                <p>
                  <strong>Push Notification</strong>
                  <br />
                  Admin can send notification to all users in single click or
                  one to one for any particular user or categories wise.
                </p>
                <p>
                  <strong>Fantasy Points Update</strong>
                  <br />
                  Admin can manage fantasy points from admin panel.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6 mt-2 pe-2">
                <p>
                  <strong>⚽MOBILE APP⚽</strong>
                </p>
                <p>
                  <strong>User Authorization</strong>
                  <br />
                  Social Media Login and Signup, Manual Signup with referral
                  code.
                </p>
                <p>
                  <strong>Matches</strong>
                  <br />
                  Show all upcoming, Live and result matches list, Filter match
                  as per the series and date.
                </p>
                <p>
                  <strong>Create Team</strong>
                  <br />
                  Create your fantasy team, with their receptive credits points.
                </p>
                <p>
                  <strong>Join Contest</strong>
                  <br />
                  Users can join any contest, users required to pay entry fees
                  to participating any contest.
                </p>
                <p>
                  <strong>Private Contest</strong>
                  <br />
                  Users can create private contest &amp; settled winners
                  percentage as per your strategy.
                </p>
                <p>
                  <strong>User Dashboard</strong>
                  <br />
                  User can see all their personal information, Total joining
                  contests, Offers and prizes.
                </p>
                <p>
                  <strong>Refers and Earn</strong>
                  <br />
                  User can invite their friends via a refer code through any
                  social media channels and get bonus amount.
                </p>
                <p>
                  <strong>User verification</strong>
                  <br />
                  User can upload for verification their pancard, Account
                  details and email, all data will be secured through third
                  party security.
                </p>
                <p>
                  <strong>Winning amount</strong>
                  <br />
                  User winning amount will be shown instantly after the match
                  finish.
                </p>
                <p>
                  <strong>Leaderboard</strong>
                  <br />
                  In contest we will show all joining team and user can download
                  whole team that particular contest.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid fantesy-section2">
          <div className="container">
            <div className="row py-5 my-5">
              <h1 className="text-center">ALL FEATURES</h1>
              <div className="col-sm-12 col-xl-6 py-5">
                <img src={fantesy1} alt="" />
                <div>
                  <p className="fs-4">
                    - Latest UI With Material Design( Year 2023)
                    <br />
                    - All Device Compatibility
                    <br />
                    - Category Wise All Match Listing
                    <br />
                    - Create Contact (Entry fee)
                    <br />
                    - Paytm Payment Add Feature.
                    <br />
                    - Fully Clone Like Dream11
                    <br />
                    - Cricket API Feature Integert*
                    <br />
                    - Application Share With Facebook, WhatsApp, Instagram,
                    Twitter And Other Application
                    <br />
                    - Download Team List
                    <br />
                    - Create Team Like Dream11
                    <br />
                    - Pan Card Kyc
                    <br />
                    - Login/Registration Features
                    <br />
                    - Mange profile feature
                    <br />
                    - API Feature Integert
                    <br />
                    - FAQ and Contact Us form
                    <br />
                    - Added full screen video
                    <br />
                    - Added payment mode in admin panel settings. This is used
                    when user fill reward point claim form and select payment
                    mode
                    <br />
                    - Latest Navigation View
                    <br />
                    - Rate App, More App And Share App links
                    <br />
                    - Check Network Availability
                    <br />
                    - Admob Integrated With Banner, Interstitial And Rewarded
                    Video Ads
                    <br />
                    - Easy Admin Panel For Manage All The Video And User
                    <br />
                    - OneSignal Push Notification
                    <br />- Android Studio Code With Latest Version
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6 py-5">
                <p>
                  <strong>ADVANCED FEATURES</strong>
                  <br />
                  Live match score
                  <br />
                  we can integrate live scoring API as per the clients
                  requirements.
                </p>
                <p>
                  <strong>Infinite/Percentage contests</strong>
                  <br />
                  We can share infinite and percentage contest, so clients never
                  loss in this contest, winning amount is automatically settled
                  as per the contest.
                </p>
                <p>
                  <strong>Real time match report</strong>
                  <br />
                  Admin can check real time match reports live contest filling,
                  Total users and add cash.
                </p>
                <p>
                  <strong>Multiple payment gateway</strong>
                  <br />
                  Admin can activate and deactivate any payment gateway as per
                  their needs.
                </p>
                <p>
                  <strong>Affiliate program</strong>
                  <br />
                  This is very important features for app owners, now you can
                  add any YouTubers or any third party as an affiliate.
                </p>
                <p>
                  <strong>Loyalty Program</strong>
                  <br />
                  We have created a users levels, High scoring players, Series
                  leaderboard and maximum winning amount players.
                </p>
                <p>
                  <strong>Players Information</strong>
                  <br />
                  Users can check every player information, their past matches
                  history, their roles etc.
                </p>
                <p>
                  <strong>CMS panel integrated</strong>
                  <br />
                  Admin can change their website Privacy policy, Terms and
                  condition page, fantasy Points system, etc.
                </p>
                <p>
                  <strong>2nd innings Contest</strong>
                  <br />
                  We have fantasy 2nd innings contest option, user can create
                  team after the 1st innings complete.
                </p>
                <p>
                  <strong>Slotting Over contests</strong>
                  <br />
                  User can join contest in over slots as like GAMEZY, Admin can
                  create contest for over slots like 5-10, 10-15 overs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid fantesy-section3">
          <div className="container">
            <h1 className="text-center py-5">
              ⚽ Fantasy Cricket: The Game Changer⚽
            </h1>
            <div className="row py-5">
              <div className="col-sm-12 col-xl-4">
                <div className="card my-3">
                  <img src={fantesy2} className="card-img-top" alt="..." />
                </div>
                <p className="fs-5">
                  <strong>Startup</strong>
                  <br />
                  - Application Android+ Website(Informative)
                  <br />
                  - Sports(Any One)
                  <br />
                  - Cricket, Football, Kabaddi, Basketball, Handball etc.
                  <br />
                  - UI/UX &amp; LOGO Standard
                  <br />
                  - 3rd party API integration
                  <br />
                  - Score, SMS and Email
                  <br />
                  - Server Setup Cost Free
                  <br />
                  - Admin panel
                  <br />
                  - Payment Gateway
                  <br />
                  - Paytm, Razorpay, Cashfree
                  <br />- Free Bug Support For 100 Days
                </p>
              </div>
              <div className="col-sm-12 col-xl-4">
                <div className="card my-3">
                  <img src={fantesy3} className="card-img-top" alt="..." />
                </div>
                <p className="fs-5">
                  <strong>Standard</strong>
                  <br />
                  - Application Android or iOS + Website(Functional)
                  <br />
                  - Sports(Any One)
                  <br />
                  - Cricket, Football, Kabaddi, Basketball, Handball etc.
                  <br />
                  - UI/UX &amp; LOGO Standard
                  <br />
                  - 3rd party API integration
                  <br />
                  - Score, SMS and Email
                  <br />
                  - Server Setup Cost Free
                  <br />
                  - Admin panel
                  <br />
                  - Payment Gateway
                  <br />
                  - Paytm, Razorpay, Cashfree
                  <br />- Free Bug Support For 100 Days
                </p>
              </div>
              <div className="col-sm-12 col-xl-4">
                <div className="card my-3">
                  <img src={fantesy2} className="card-img-top" alt="..." />
                </div>
                <p className="fs-5">
                  <strong>Premium</strong>
                  <br />
                  - Application Android or iOS + Website(Functional)
                  <br />
                  - Sports(Any One)
                  <br />
                  - Cricket, Football, Kabaddi, Basketball, Handball etc.
                  <br />
                  - UI/UX &amp; LOGO Standard
                  <br />
                  - 3rd party API integration
                  <br />
                  - Score, SMS and Email
                  <br />
                  - Server Setup Cost Free
                  <br />
                  - Admin panel
                  <br />
                  - Payment Gateway
                  <br />
                  - Paytm, Razorpay, Cashfree
                  <br />
                  -Free Bug Support For 100 Days
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid py-5 fantesy-section-4">
          <div className="container py-5">
            <h3>The Evolution of Fantasy Cricket</h3>
            <p>
              Fantasy cricket has come a long way since its inception. It
              started as a niche concept, captivating the imaginations of
              cricket fans who wanted to be more than just spectators. Fast
              forward to today, and it has become a mainstream phenomenon,
              attracting millions of users worldwide.
            </p>
            <h4>From Paper to Pixels</h4>
            <p>
              The journey of fantasy cricket began on paper. Enthusiasts would
              manually calculate points based on the real-life performances of
              cricketers and compete with friends. However, with the advent of
              the internet and mobile apps, the game transitioned into the
              digital realm, making it accessible to anyone with a smartphone.
            </p>
            <h4>&nbsp;Leagues</h4>
            <p>
              One of the most significant developments in fantasy cricket is the
              introduction of&nbsp; leagues. Players can now participate in
              contests with real cash prizes, elevating the thrill and
              competitiveness to a whole new level. This has transformed fantasy
              cricket from a casual hobby to a serious pursuit for many.
            </p>
            <h4>How Fantasy Cricket Works</h4>
            <p>
              Fantasy cricket operates on a simple premise: users create virtual
              teams of real cricketers who are playing in upcoming matches.
              Points are awarded to these virtual teams based on the actual
              performance of the selected cricketers in the real game.
            </p>
            <h4>Player Selection</h4>
            <p>
              In a typical fantasy cricket game, users are given a virtual
              budget to create their dream team. They must strategically choose
              a combination of batsmen, bowlers, all-rounders, and
              wicket-keepers while staying within the budget constraints. This
              requires a keen understanding of player form, pitch conditions,
              and team dynamics.
            </p>
            <h4>Scoring System</h4>
            <p>
              The scoring system in fantasy cricket is comprehensive, taking
              into account various aspects of a player’s performance. Runs
              scored, wickets taken, catches held, and even strike rates all
              contribute to a player’s total points. This intricate system
              rewards users for their in-depth knowledge of the game.
            </p>
            <p>&nbsp;</p>
            <h4>Real-time Updates</h4>
            <p>
              As the real game unfolds, fantasy cricket users receive real-time
              updates on how their selected players are performing. This adds an
              extra layer of excitement, as every boundary, wicket, or six can
              have a direct impact on their fantasy team’s fortunes.
            </p>
            <p>&nbsp;</p>
            <h4>Why Fantasy Cricket is a&nbsp; Game</h4>
            <p>
              Fantasy cricket isn’t just about bragging rights; it’s about
              winning . Here’s why it’s become the go-to choice for cricket fans
              looking to cash in on their cricket knowledge.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid fantesy-section-5">
          <div className="container">
            <img src={fantesy4} alt="" />
          </div>
        </div>
      </section>
      <FantesyFaq />
      <Footer />
    </>
  );
};

export default FantesyGame;
