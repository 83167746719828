import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import winner from "../image/8020579-scaled.webp";
const Animationimage = () => {

    useEffect(() => {
        AOS.init();

    },[])
    return (
        <>
            <section className="my-5">
                <div className="container-fluid text-section">
                    <div className="container mb-5 mt-5">
                        <div className="row py-3 justify-content-around">
                            <div className="col-sm-12 col-lg-5 mt-sm-1  p-sm-1 img-text my-3">
                                <h1 className="text-dark">Casino, Board Game Development</h1>
                                <span>
                                    Maxway Infotech has been delivering multiple projects of
                                    mobile games, mobile software applications both for business
                                    and individuals globally. If you are searching for a
                                    professional, experienced, and globally trusted solution
                                    partner that believes in quality delivery within time-lines,
                                    We are here !
                                </span>
                                <div className="btnGet mt-2">
                                    <button style={{backgroundColor: '#1e73be'}}>
                                    <Link className="hLink getTxtLive" to="/About"><i className="fa-solid fa-comments-dollar"></i>Get Live Demo</Link>
                                    </button>
                                </div>
                            </div>
                            <div className="col-sm-12 col-xl-5 my-5">
                                <div data-aos="fade-down-left">
                                    <img src={winner} className="img-fluid" alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Animationimage