import React from 'react';
import { Link } from "react-router-dom";
import error from "../image/404.webp";

const ErrorPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <img src={error} alt="" className="errorimg" />

            <h1 className="text-center mt-5">Sorry We Can't Find That Page!</h1>
            <p className="fs-3 text-center">The page you are looking for was moved, removed, renamed or never existed.</p>

            <button className="bulkWh w-25"><Link className="hLink" to="/">Take ME HOME</Link> </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ErrorPage