import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const WindowTop = () => {
    let { pathname } = useLocation();
    useEffect(()=>{
        window.scrollTo(0,0);

    },[pathname])
  return (
    <></>
  )
}

export default WindowTop