import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { RiAppsFill } from "react-icons/ri";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
function Footer() {
  return (
    <>
      <div className="container-fluid footerss pt-5">
        <div className="container mt-sm-1 mt-lg-5 mx-auto">
          <div className="row mb-sm-1 mb-lg-5">
            <div className="col-sm-12 col-lg-4 my-5 ">
              <h4 className="mb-sm-1 mb-lg-5">About Maxway Infotech</h4>
              <h6>Game Development: Immerse Your</h6>
              <h6>audience in captivating interactive experiences</h6>
              <h6>experiences with our game</h6>
              <h6>development experties. Our talented</h6>
              <h6>team creates interactive and</h6>
              <h6>entertaining games for various</h6>
              <h6>platforms.</h6>
              <div>
                <i className="fa-brands fa-twitter"></i>
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-brands fa-linkedin"></i>
                <i className="fa-brands fa-paypal"></i>
              </div>
            </div>
            <div className="col-sm-12 col-lg-2 my-5">
              <h4 className="mb-sm-1 mb-lg-5">B2B, B2C & Reseller</h4>

              <h6>Recharge</h6>
              <h6>AEPS</h6>
              <h6>BBPS</h6>
              <h6>mATM & mPOS</h6>
              <h6>Pancard</h6>
              <h6>Aadhar Pay</h6>
              <h6>Contact-Us</h6>
            </div>
            <div className="col-sm-12 col-lg-3 my-5">
                <h4 className="mb-sm-1 mb-lg-5">Contacts</h4>
                <h6><FaLocationDot/>Near Amrapali Circle,</h6>
                <h6>Vaishali Nagar,Jaipur,Rajasthan</h6>
                <h6>302021</h6>
                <h6><RiAppsFill/>maxwayinfotech.com</h6>
                <h6><IoMdMail/>maxwayinfotech1@gmail.com</h6>
                <h6><FaPhoneAlt/>+91 9116672785</h6>
                <h6><IoLogoWhatsapp/> +91 9116672785</h6>
            </div>
            <div className="col-sm-12 col-lg-3 my-5">
                <h4 className="mb-sm-1 mb-lg-5">Other Service & Software</h4>
                <h6>Website Design</h6>
                <h6>Website Development</h6>
                <h6>Digital Marketing</h6>
                <h6>Bus Booking Softwere</h6>
                <h6>Flight Booking Softwere</h6>
                <h6>Hotal Booking Softwere</h6>
            </div>
          </div>
          <div className="row mt-sm-1 mt-lg-5 footerss-bootom">
            <div className="col-sm-12 col-lg-6 ">
                <p className="terms">Terms of | Privacy Environmental Policy</p>
            </div>
            <div className="col-sm-12 col-lg-6 ">
                <p className="copyright">Copyright@2022 Maxway Infotech All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
