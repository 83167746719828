import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img from "../image/5492-scaled (1).webp";
import Animationimage from "./Animationimage";
import Counter from "./Counter";
import { Link } from "react-router-dom";
import 'animate.css';

function Cooman() {
  useEffect(() => {
    AOS.init();

  }, [])
  return (
    <>
      <section className="container-fluid bg-img mb-5 " data-aos="zoom-in-up" >
        <div className="container ">
          <div className="row mx-auto text-center " >
            <div className="col-sm-12  col-xl-7 img-contant">
              <h1 className="mt-sm-1">
                Get Innovative & Cost-Effective Custom Solutions for Business
              </h1>

              <h5 className="mt-sm-1 mb-sm-1">
              Complete IT Solutions and Game Website Services
              </h5>
              <div className="img-btn mt-sm-1 mb-sm-1">
                <button className="mt-sm-0 mt-lg-5">Let's talk</button>
                <button className="mt-sm-0 mt-lg-5">
                  <i className="fa-regular fa-paper-plane"></i> Free Live Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* This Section In Complete background Color and Image Is no Found  */}
      <section className="my-5" data-aos="zoom-in-down">
        <div className="container-fluid img-section">
          <div className="container">
            <div className="row py-3 justify-content-around">
              <div className="col-sm-12 mt-2 col-xl-5">
                <img src={img} className="img-fluid" alt="" />
              </div>
              <div className="col-sm-12  col-xl-5 img-text">
                <h1>Fantasy Cricket: The Game Changer</h1>
                <b>Maxway Infotech's</b>
                <span>
                  &nbsp;team of skilled fantasy sports app developers in India
                  that are highly experienced in developing all types of fantasy
                  sports apps including fantasy cricket app development. Our
                  proficient developers can develop fantasy game apps for
                  other sports also such as football, kabaddi, hockey, tennis
                  etc.
                </span>
                <div className="mt-2 btnGet" >
                  <button style={{backgroundColor: '#1e73be'}}>
                    <Link className="getTxtLive" to="/About"><i className="fa-solid fa-comments-dollar"></i>Get Live Demo</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Animationimage />
      <section>
        <div className="container-fluid my-5">
          <div className="container my-5">
            <div className="row text-center my-5">
              <div className='' style={{display:'flex',justifyContent:'center'}}>
              <h2 className="my-3 highlight-blue">Explore Our Products</h2>
              </div>
              <h5 className="my-2">
              Crafting Seamless Digital Solutions for Every Screen
              </h5>
           
            </div>
            <div className="row mx-auto justify-content-center " data-aos="fade-right">
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="whatsapp">
                  <i className="fa-brands fa-whatsapp"></i>
                </div>
                <div className="whatsapp-text">
                  <h3>Business Whatsapp Marketing</h3>
                </div>
              </div>
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="whatsapp">
                  <i className="fa-solid fa-gamepad text-danger"></i>
                </div>
                <div className="game-text">
                  <h3>2D/3D Game Development</h3>
                </div>
              </div>
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="whatsapp">
                  <i className="fa-solid fa-gamepad text-primary"></i>
                </div>
                <div className="game-text">
                  <h3 className="infobox_title">ios/Android Development</h3>
                </div>
              </div>
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="dragon">
                  <i className="fa-solid fa-dragon"></i>
                </div>
                <div className="whatsapp-text">
                  <h3>CRM Softwere / Web Development</h3>
                </div>
              </div>
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="whatsapp">
                  <i className="fa-solid fa-shuffle text-danger"></i>
                </div>
                <div className="game-text">
                  <h3>B2B Business</h3>
                </div>
              </div>
              <div className="col-sm-12  col-xl-4 my-5">
                <div className="whatsapp">
                  <i className="fa-solid fa-hand-pointer text-primary"></i>
                </div>
                <div className="game-text">
                  <h3 className="infobox_title">Digital Marketing</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Counter />
      <section>
        <div className="container-fluid my-5 text-center about1-text" data-aos="zoom-in-down">
          <div className="container mx-auto my-5">
          <div style={{display:'flex',justifyContent:'center'}}>
          <h2 className='highlight-bluewhat'>What Clients Say About Us</h2>
          </div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'20vh',marginTop:'12px'}}>
          <h5>
            "Maxway Infotech's clients and their results are the best proof of our methodologies. Additionally, we have received numerous awards and praise from the media and our peers"
            </h5>
          </div>
          </div>
          <div className="container">
            <div className="row  justify-content-around">
              <div className="col-sm-12  col-xl-3 shadow-lg clint-feedback mb-5">
                <div className="row border-bottom">
                  <div className="col-8">
                    <h3>Raj Kumar</h3>
                  </div>
                  <div className="col-2">
                    <h3 className="text-primary text-end">
                      <i className="fa-solid fa-envelope"></i>
                    </h3>
                  </div>
                </div>
                <div className="row   p-5 text-start">
                  <span className="lh-lg">
                    “Maxway Infotech consistently delivers excellent results. We
                    enjoy working with them and have seen a strong return for
                    our investment.”
                  </span>
                </div>
              </div>
              <div className="col-sm-12  col-xl-3 shadow-lg clint-feedback mb-5">
                <div className="row border-bottom">
                  <div className="col-8">
                    <h3>Sunil Kumar</h3>
                  </div>
                  <div className="col-2">
                    <h3 className="text-primary text-end">
                      <i className="fa-solid fa-envelope"></i>
                    </h3>
                  </div>
                </div>
                <div className="row   p-5 text-start">
                  <span className="lh-lg">
                    “Maxway Infotechconsistently delivers excellent results. We
                    enjoy working with them and have seen a strong return for
                    our investment.”
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-xl-3  shadow-lg clint-feedback mb-5">
                <div className="row border-bottom">
                  <div className="col-8">
                    <h3>Komal Soni</h3>
                  </div>
                  <div className="col-2">
                    <h3 className="text-primary text-end">
                      <i className="fa-solid fa-envelope"></i>
                    </h3>
                  </div>
                </div>
                <div className="row   p-5 text-start">
                  <span className="lh-lg">
                    “Maxway Infotechconsistently delivers excellent results. We
                    enjoy working with them and have seen a strong return for
                    our investment.”
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cooman;
