import React from 'react';
import Header from '../Header';
import Cooman from '../Cooman';
import Carosal from '../Carosal';
import Footer from '../Footer';
import GotoTop from '../GotoTop';
import WindowTop from '../WindowTop';
import WhatsappFixed from '../WhatsappFixed';
import PageLoading from '../PageLoading';


const Home = () => {

    return (
        <>
            <WindowTop />
            <PageLoading type = {'Home'} />
            <GotoTop />
            <Header />
            <Cooman />
            <Carosal />
            <WhatsappFixed />
            <Footer />
        </>
    )
}

export default Home