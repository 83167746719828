import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import websiteImg from "../image/Screenshot-2023-09-27-105342.webp";
import websiteImg2 from "../image/e-commerce-banner-imag.webp";
import websiteImg3 from "../image/Screenshot-2023-09-27-104916.webp";
import websiteImg4 from "../image/Screenshot-2023-09-27-104748.webp";
import websiteImg5 from "../image/Screenshot-2023-09-27-104837.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";


const Website = () => {
  return (
    <>
      <WhatsappFixed />
      <PageLoading type='Website design developmen' />
      <WindowTop />
      <GotoTop />
      <Header />
      <section>
        <h1 className="text-center my-5">Website design &amp; development</h1>

        <div className="container-fluid website-page">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <h2 className="fs-2 px-4 text-light">
                  COMPLETE WEB DESIGN &amp; DEVELOPMENT
                </h2>
                <div>
                  <p>
                    Say goodbye to sleepless nights over counterproductive
                    communications between service providers, and no more
                    overbilling-related nightmares for services you’re not sure
                    you need but you’re sure you have to pay for.
                  </p>
                </div>
                <button>STARTS NOW</button>
              </div>
              <div className="col-sm-12 col-lg-6">
                <img src={websiteImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page1">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xl-6 ">
                <img src={websiteImg2} alt="" />
              </div>
              <div className="col-sm-12 col-xl-6 ">
                <h2 className="fs-2  text-light">
                  We Deliver Memorable E-Commerce Website and App development
                </h2>
                <div>
                  <p>
                    Give your users a seamless experience by testing on 3000+
                    real devices and browsers. Don’t compromise with emulators
                    and simulators
                  </p>
                </div>
                <button> <Link className="hLink" to="/website">ORDER NOW</Link></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page3 my-5">
          <div className="container">
            <p>
              Configuration is the method involved with gathering thoughts, and
              stylishly orchestrating and carrying out them, directed by
              specific standards for a particular reason. Website architecture
              is a comparative course of creation, fully intent on introducing
              the substance on electronic site pages, which the end-clients can
              access through the web by means of different electronic gadgets.
            </p>
            <p>
              Quick version, web composition is Arranging, Creation and the
              consistent refreshing on sites. The web composition likewise
              includes data engineering, UI (UI), site center (structure), route
              ergonomics, site format, colors, differentiations, text styles and
              symbolism overall (photography, symbol plan and so on.).
            </p>
            <p>
              Every one of the previously mentioned components structure a site
              when consolidated. As a rule, plan is being seen as a visual
              perspective just, which is a serious mix-up. In all actuality the
              entire idea of the plan incorporates more unique components, for
              example, ergonomics, ease of use, design customs, routine
              propensities of the clients, route rationale and a lot of
              different things that are there to work on the usage of the
              offered site and consequently making a chance to find the ideal
              data as the Client much faster.
            </p>
            <p>
              In many events the specialized part of the web architecture is
              being accentuated by the meaning of plan. The cutting edge site
              building includes server side prearranging like asp, CGI and PHP,
              the sites visual side is being characterized by HTML and CSS and
              the client experience is being improved by a unique JavaScript or
              Ajax. While conversing with individuals with to a lesser extent a
              mechanical training foundation, we will generally zero in on the
              functionalities and how much it is workable as far as we’re
              concerned to refresh a site through satisfied administration,
              while taking regarding the elements that are noticeable to
              clients.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page4 py-5">
          <div className="container">
            <h2 className="text-center fs-1 my-4 py-3">
              Our Web development service includes
            </h2>
            <div className="row">
              <div className="col-sm-12 col-xl-6">
                <ul>
                  <li>Static Website Development</li>
                  <li>Business Analytics Wesit Development</li>
                  <li>Website Development</li>
                  <li>Tour &amp; Travel Portal Development</li>
                  <li>E-Commerce Website Development</li>
                  <li>CMS Website Development</li>
                  <li>Open Source Development</li>
                  <li>Hotel Booking Websit development</li>
                  <li>education portal Websit development</li>
                  <li>Credit card Websit development</li>
                  <li>Business Management Websit Development</li>
                  <li>News Blogs Websit Development</li>
                  <li>Movies Blogs Websit Development</li>
                </ul>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={websiteImg3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page5 py-5 ">
          <div className="container">
            <h2 className="text-center fs-1 my-4 py-3">
              Our website development process works like this :-
            </h2>
            <div className="row">
              <div className="col-sm-12 col-xl-6">
                <img src={websiteImg4} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  Understanding Customer’s need
                  <br />
                  Cost discussion
                  <br />
                  Planning of project
                  <br />
                  Technology Assessment
                  <br />
                  Mockups Design
                  <br />
                  Web Designing
                  <br />
                  Web Development
                  <br />
                  Quality Assurance
                  <br />
                  Deployment
                  <br />
                  Ongoing Support
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container text-center">
            <div className="row g-1 py-5 d-flex justify-content-between ">
              <div className="col-sm-12 col-xl-4 shadow px-2 py-5 border-1">
                <div className="website-icon">
                  <i className="fa-regular fa-paper-plane"></i>
                </div>
                <h3 className="fs-3 my-3">Front-end development</h3>
                <p className="fs-4 px-4 ">
                  Our front-end developers can implement any design idea and
                  ensure all interface elements work properly. We work with all
                  most-used JavaScript frameworks, such as Angular, React,
                  Meteor, Vue, Next, Ember.
                </p>
              </div>
              <div className="col-sm-12 col-xl-4 shadow px-2 py-5 border-1">
                <div className="website-icon">
                  <i className="fa-solid fa-gear"></i>
                </div>
                <h3 className="fs-3 my-3">Back-end development</h3>
                <p className="fs-4 px-4 ">
                  Our developers accurately implement the business logic of your
                  web app on the back end. We rely on proven frameworks and
                  ensure fast and quality coding in .Net, Java, Python, Node.js,
                  PHP, Go.
                </p>
              </div>
              <div className="col-sm-12 col-xl-4 shadow px-2 py-5 border-1">
                <div className="website-icon">
                  <i className="fa-solid fa-arrow-up-right-dots"></i>
                </div>
                <h3 className="fs-3 my-3">Business Analysis</h3>
                <p className="fs-4 px-4 ">
                  our business analusts focus on the need og your target
                  audience to perform requirements engineering and outline the
                  scope of the solution. they alse bridge the gap between
                  businees stakeholders and an it team to keep all the involved
                  parties aligned.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page6">
          <div className="container">
            <div className="row">
              <h2 className="text-center fs-1 my-4">
                Web Development Company in India, Cost-Effective Website
                Development
              </h2>
              <div>
                <p>
                  Till now we have developed good relationships with plenty of
                  clients and still counting. Along with creating websites, we
                  transform them into powerful communication channels to enhance
                  online growth and deliver your desired result.
                </p>
                <p>
                  We believe in delivering web development solutions within the
                  expected budget and timeframe. <span>Maxway Infotech </span>
                  make it more important for brands to be accessible everywhere,
                  in real-time and both desktop and mobile devices.
                </p>
                <p>
                  Our team of creative professionals and developers are trained
                  to understand the client’s requirement first. This helps in
                  delivering the expected results with 100% satisfaction. Being
                  one of the best web development companies in India, we provide
                  rich and interactive user experience.
                </p>
                <p>
                  We build clean, clear and customized websites to create
                  lasting impressions both visually and interactively. Our web
                  development teams combine their expertise with latest
                  technologies and trends to resolve all your business
                  challenges and needs.
                </p>
                <p>
                  We create your websites while keeping your business goals in
                  mind. Our professional team of experts combines their
                  technical expertise and creative instincts to create unique
                  and extraordinary solutions for your business.
                </p>
                <p>
                  Our complete web development and e-commerce solutions includes
                  site hosting, merchant account, payment gateway, back-end
                  merchant management of your store or products, real-time order
                  processing, real-time credit card authorization, customer
                  account management, order history, sales tracking, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page7 py-5">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <img src={websiteImg5} alt="" className="img-fluid " />
              </div>
              <div className="col-sm-12 col-xl-6">
                <h2 className="text-start fs-1 my-4">We at Maxway Infotech are glad to offer you:</h2>
                <p>•&nbsp;Custom Web Design<br />
                  •&nbsp;Responsive Web Design<br />
                  •&nbsp;Landing Page Design<br />
                  •&nbsp;Corporate Web Design<br />
                  •&nbsp;Open Source Web Design<br />
                  •&nbsp;E-Commerce Web Design<br />
                  •&nbsp;Logo Design<br />
                  •&nbsp;Mobile apps UI Design<br />
                  •&nbsp;CMS Themed Design</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid website-page3">
          <div className="container">
            <div className="row">
              <p>Those are only a couple of the administrations that we might accommodate you, yet we generally ensure the quality is at the most elevated level conceivable and we can oblige to any clients want.</p>
              <p>Our group had been gathered in such a way, to have the option to give simply the greatest web composition administration and upkeep of the equivalent. We are taking difficulties from clients dwelling everywhere. We have practical experience in planning a tasteful, excessive web composition, while keeping up with the hint of responsiveness and unwavering quality. In actuality, making first rate business leads.</p>
              <p>Feel free to us and find the other driving organizations in their separate regions!</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Website;
