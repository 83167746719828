import React from "react";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

const FantesyFaq = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <MDBAccordion flush initialActive={0}>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="Which is the best fantasy cricket app in India?"
            >
              <p>
                <span>
                  The First Games app, specifically handcrafted by Paytm to
                  offer convenience and the best game experience to the Indian
                  audience, happens to be the best fantasy cricket app in India.
                </span>
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="How do you pick players in fantasy cricket?"
            >
              <p>
                <span>
                  Basic research and knowledge of the game, teams, players, and
                  their performance records plays an important role in picking
                  the players to form a team in fantasy cricket. While lots of
                  instinct and less luck is required in picking the right
                  players from both the teams, it is very important in the game
                  that a gamer keeps favouritism aside and is not partial in
                  selecting his/her favourite players irrespective of whether
                  they are performing well or not.
                </span>
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Which is the free fantasy cricket app?"
            >
              <p>
                <span>
                  First Games app is the free fantasy cricket app wherein game
                  enthusiasts can play free games as well as cash games online.
                </span>
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={4}
              headerTitle="Are fantasy apps legal in India?"
            >
              <p>
                <span>
                  Yes, fantasy apps are legal in India since it requires and
                  involves a lot of skills and strategies to play. All skill
                  based online games in India are protected by the law under the
                  Article 19(1)(g) of the Indian Constitution.
                </span>
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={5}
              headerTitle="Which fantasy app is best for winning real cash?"
            >
              <p>
                <span>
                  The First Games app that is handcrafted by Paytm is the best
                  fantasy app in India to win real cash as it also allows the
                  players and participants to withdraw their winnings instantly.
                </span>
              </p>
            </MDBAccordionItem>
          </MDBAccordion>
        </div>
      </div>
    </>
  );
};

export default FantesyFaq;
