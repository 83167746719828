import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import bulksmsImg from "../image/Screenshot-2023-09-28-171351.webp";
import bulksmsImg1 from "../image/Screenshot-2023-09-28-185125 (1).webp";
import bulksmsImg2 from "../image/Screenshot-2023-09-28-184749.webp";
import bulksmsImg3 from "../image/Screenshot-2023-09-28-184923.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";

const BulkSms = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='Bulk SMS' />

      <section>
        <div className="container-fluid">
          <div className="container">
            <h1 className="text-center my-5">Bulk SMS</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulksms">
          <div className="container">
            <div className="row my-5">
              <h2 className="text-center fs-2 weight700 my-4">
                Maxway Bulk SMS for India
              </h2>
              <p>
                Get your messages across with maxwayinfotech.com. Use any or all
                of our SMS Messaging Solutions whenever you need to. Using our
                platform, you can quickly send any short and important SMS
                messages worldwide, or locally to India. You can easily send
                transactional messages like one-time passcodes and SMS alerts,
                or promotional messages from your CRM system or sales desk. Look
                at our case studies to see how our customers are using our SMS
                gateway.
              </p>
              <h3 className="p-0">
                There are two delivery routes into India – Transactional and
                Promotional
              </h3>
              <p>
                The default is for messages to be routed via the Promotional
                route. Under Indian regulations this means that messages will
                only be delivered between 9am and 9pm Indian time, and will not
                be delivered to any customers registered on the official “Do Not
                Disturb” list which anyone in India can register for. Many
                people in India have registered themselves on this list, and
                they will therefore not receive promotional messages.
              </p>
              <p>
                The Transactional route is available, and has neither the limit
                on time of day, nor the “Do Not Disturb” check for delivery.
                However, this route can only be used for traffic which is
                genuinely transactional – ie. it is traffic which the user is
                expecting and initiated as something they are doing or have
                signed up to – sending PIN codes, confirmation messages, etc.
                would fall into this category. Marketing material and
                unsolicited messages would not. This route can only be used upon
                pre-registration of a message template. Click here for further
                information on transactional SMS messaging to India and to
                pre-register your SMS message template.
              </p>
              <div className="col-sm-12 col-xl-6">
                <p>
                  You can Send Text SMS to your client for share information and
                  other details._
                </p>
                <p>
                  ✔️ Text SMS of Operator.
                  <br />
                  ✔️ Dynamic SMS From Excel.
                  <br />
                  ✔️ Multi Language Support.
                  <br />
                  ✔️ 24 hour&amp;#39;s Fast Delivery.
                  <br />
                  ✔️ 90 to 100% Delivery Ratio.
                  <br />
                  ✔️ API Available for Integration.
                  <br />
                  ✔️ Sent by Your Brand Name if have DLT.
                  <br />
                  ✔️ DLT Registration within 2 days.
                  <br />
                  ✔️ You can Send Without DLT via Default Sender Id.
                  <br />
                  ✔️ Promotional &amp;Transactional Both Available
                  <br />
                  ✔️ Sender ID Base Massage on DND &amp; Non DND Numbers Both
                  <br />
                  ✔️100% Delivery
                  <br />
                  ✔️ Transactional SMS time 24/7
                  <br />
                  ✔️Promotional SMS time 9AM TO 9PM
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={bulksmsImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulksms1">
          <div className="container">
            <div className="row mb-5">
              <h2 className="text-center my-5">Maxway Bulk Email for India</h2>
              <p>
                If you drive leads and sales for your business via emails, you
                know that regular email services like Gmail and Outlook are
                never enough.
              </p>
              <p>This is why you need a bulk email sending service.</p>
              <p>
                Bulk email sending services are email providers that can send
                mass emails to a large number of people at a time. Using these
                tools is an easier and more efficient means to send out sales
                promotions, newsletters, and company announcements.
              </p>
              <p>
                Besides providing direct access to your customer base, bulk
                email senders also help you track the performance of your emails
                using important email marketing metrics. This way, they help you
                improve your chances of increasing your click-through rates and
                conversions.
              </p>
              <p>
                However, getting the right bulk email sending service at an
                affordable price can be challenging. But don’t worry we’ve done
                most of the work for you by collecting 10 of the best bulk email
                sending services.
              </p>
              <p>
                Before we dive in, it’s important to show you what to look out
                for in a&nbsp; bulk email sending service provider and how to
                choose the best one for your business.
              </p>
              <div className="col-sm-12 col-xl-6">
                <img src={bulksmsImg1} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>You can Send Text email to your client for share information and other details._</p>
                <p>✔️ Text Email of Operator.<br />
                  ✔️ Dynamic Email From Excel.<br />
                  ✔️ Multi Language Support.<br />
                  ✔️ 24 hour&amp;#39;s Fast Delivery.<br />
                  ✔️ 90 to 100% Delivery Ratio.<br />
                  ✔️ API Available for Integration.<br />
                  ✔️ Sent by Your Brand Name if have DLT.<br />
                  ✔️ DLT Registration within 2 days.<br />
                  ✔️ You can Send Without DLT via Default Sender Id.<br />
                  ✔️ Promotional &amp;Transactional Both Available<br />
                  ✔️ Sender ID Base Massage on DND &amp; Non DND Numbers Both<br />
                  ✔️100% Delivery<br />
                  ✔️ Transactional SMS time 24/7<br />
                  ✔️Promotional SMS time 9AM TO 9PM</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulksms">
          <div className="container">
            <div className="row mb-4">
              <h2 className="text-center my-5">Voice MSG</h2>
              <p>Quite understandably, you might be a bit reluctant to engage in a service of this sort. In the age of the internet why would you want to send a voice SMS? Well the answer lies within the question itself. Many parts of India do not have access to internet services. Many people cannot afford internet packs on their mobile phones. How would you be able to reach them? This is when a voice SMS service comes into play. Through voice SMS or bulk voice SMS, you can send pre-recorded voice notes, music, or any kind of audio message to any mobile network all across the country.</p>
              <div className="col-sm-12 col-xl-6">
                <h2>Maxway Premium Voice Msg Services</h2>
                <p>Send a Pre Recorded Voice MSG as call to your Client For delivered your voice or any information._<br />
                  ✔️ Pay Only Per Pickup Call.<br />
                  ✔️ Call Delivered from Your any Numbers.<br />
                  ✔️ 28Sec Voice Support 1 credit.<br />
                  ✔️ Answered Based Services.<br />
                  ✔️ Delivered on All DND numbers.<br />
                  100% Fast Delivery.<br />
                  Call delivered between 9am to 8pm.<br />
                  ➖➖➖➖➖➖➖➖➖➖</p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={bulksmsImg2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid bulksms1">
          <div className="container">
            <div className="row">
              <h2 className="text-center my-4 ">Voice MSG</h2>
              <p>Today the best way to promote services and business is through SIM Based Bulk SMS Service. It is very easy to avail service and utilize a number of features. No DLT registration required to avail and utilize this service. As we know after DLT registration is applied, most of the promotional business and services are not able to promote their business and we know after the pandemic the business is already down and if you are not able to do promotion through SMS then its more negative impact for business. SIM Based Bulk SMS Service delivers with the ten digit mobile number and to the non dnd numbers. This is one of the best options for promotion and cheaper than normal A2P transactional and promotional SMS. You can buy double credits for the cost of normal messages. The costing and delivery from the 10 digit number allows the sender to get more leads, because of its 10 digit virtual number sender delivery.</p>
              <p>Sending an SIM based SMS or MODEM SMS is very easy with our interface. You can also utilize all the features of SMS panel which has been developed previously only for Transactional SMS service. Some of customer have observed, that they are getting good response though the SIM based SMS service. Its deliver to the handset with the different 10 digit virtual mobile numbers so that it doesn’t go to the spam of the mobile, so customer get high response with the SIM SMS gateway. Features like; Multimedia SMS, URL Shortener, URL Track, Customize SMS, Unicode SMS, Personalize SMS, Media Tracking etc., can be used easily.</p>
              <p><span>Maxway Infotech</span> is one of the best SIM based SMS service provider company providing SIM SMS Services to all over India, SIM SMS company in India, SIM SMS with API service provider, MODEM SMS service provider, College SIM SMS sending company in India, School SIM SMS service provider in India, 100% SIM SMS delivery service provider in India, Dynamic template SIM SMS service provider in India, Non DND SIM SMS service provider in India, No.1 SIM SMS company in India, Best SIM SMS service provider in India, 100% delivery of Bulk SIM SMS provider in India, Bank SIM SMS promotion service provider in India, Real Estate SIM SMS provider, Educational SIM SMS Promotion, Loan based SIM SMS service provider. We provide SIM SMS service for all industry to send SIM based promotional SMS.</p>
              <div className="col-sm-12 col-xl-6">
                <img src={bulksmsImg3} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <h2>Maxway Sim Based BULK SMS With DND Features</h2>
                <p>✔️ Send Promotional Text SMS to your client mobile Inbox without any restrictions or approval.<br />
                  ✔️ Pay Per Msg Cost.<br />
                  ✔️ Msg Send via 10 digit Random Number.<br />
                  ✔️ No DLT Registration Required.<br />
                  ✔️ Send English &amp; Hindi SMS<br />
                  ✔️ Delivered on All DND numbers.<br />
                  100% Delivery within 3 – 4 hours.<br />
                  Msg delivered only Working Day between 10am to 6 pm.<br />
                  ➖➖➖➖➖➖➖➖➖➖</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BulkSms;
