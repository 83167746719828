import React, { useEffect } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import mobilerecharge from "../image/mobile-recharge-apiimg.webp";
import prepaidmobile from "../image/prepaid-mobile-recharge-services.webp";
import Screenshot from "../image/Screenshot-2023-09-28-133559.webp";
import Screenshot1 from "../image/Screenshot-2023-09-28-134438.webp";
import Screenshot2 from "../image/Screenshot-2023-09-28-140249.webp";
import Screenshot3 from "../image/Screenshot-2023-09-28-141812.webp";
import Screenshot4 from "../image/Screenshot-2023-09-28-144352.webp";
import Screenshot5 from "../image/Screenshot-2023-09-30-1041355.webp";
import Screenshot6 from "../image/Screenshot-2023-09-28-145250.webp";
import Screenshot7 from "../image/Screenshot-2023-09-28-145943.webp";
import Screenshot8 from "../image/Screenshot-2023-09-28-151116.webp";
import Screenshot9 from "../image/Screenshot-2023-09-28-164459.webp";
import Screenshot10 from "../image/Screenshot-2023-09-30-103350.webp";
import Screenshot11 from "../image/Screenshot-2023-09-27-153836.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";

const FinetechSoftwere = () => {
  useEffect(() => {
    AOS.init();

  }, [])
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <PageLoading type={'FinetechSoftwere'} />
      <Header />

      <h1 className="text-center my-5">Finetech Softwere</h1>

      <section>
        <div className="container-fluid">
          <div className="container finetech-page">
            <div className="row mb-5">
              <h2 className="text-center my-4">Mobile Recharge Software</h2>
              <div className="col-12">
                <p>
                  You can start a business of an online recharge with single
                  software.is offering you a one stop solution for your all in
                  one recharge business.
                </p>
                <p>
                  We provide you recharge software that allows you to do all
                  kind of recharges using single software. Our software provides
                  recharge from all the service providers available in India.
                </p>
                <p>
                  You can earn commission on every recharge done through your
                  software. A confirmation mail is sent to confirm that recharge
                  is done successfully.
                </p>
                <p>
                  Benefits of Multi Recharge Software
                  <br />
                  We have updated our recharge system as per the market demands
                  with latest features which are helpful in making recharge
                  service more reliable, flexible and accurate with
                  functionality and performance.
                </p>
                <p>
                  We have developed this software according to the trends in
                  online business and it is made on multi recharge system. The
                  software is user friendly and fully supported by all the
                  search engines.
                </p>
                <p>
                  We have also optimized the software according to the
                  requirements of customers to increase traffic and grow
                  business.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6" data-aos="fade-right">
                <p>
                  Following are the benefits of Multi Recharge Software <br />
                  1. Works with all operators available in India
                </p>
                <p>2. Fully equipped backend and database support</p>
                <p>3. Covers all over India with online recharge business</p>
                <p>4. Quick and relatively fast mobile recharge service</p>
                <p>
                  5. Assist you with DTH recharge and Data Card recharge also
                </p>
                <p>
                  6. Online Wallet System that helps you to maintain one account
                  for all recharges
                </p>
                <p>7. Accuracy Of Data</p>
                <p>8. 99% success ratio that create trust among customers</p>
                <p>9. No physical top-up and recharge cards needed</p>
                <p>10. Profit Increased based on the number of transactions</p>
                <p>11. No need to maintain minimum balance in wallet</p>
                <p>
                  12. Freedom to create more distributors and retailers to work
                  for you
                </p>
              </div>
              <div className="col-sm-12 col-xl-6" data-aos="fade-left" style={{display:'flex',alignItems:'center'}}>
                <img src={mobilerecharge} alt="" className=""  style={{width:'100%',height:'60vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6 mt-3" data-aos="fade-right" style={{display:'flex',alignItems:'center'}}>
                <img src={prepaidmobile} alt="" style={{width:'100%',height:'70vh',borderRadius:'25px'}}/>
              </div>
              <div className="col-sm-12 col-xl-6 mt-3" data-aos="fade-left">
                <ul>
                  <li>
                    Master Distributor/ Distributor/ Retailer can recharge
                    through SMS, Portal and Mobile Application.
                  </li>
                  <li>
                    In our B2B recharge software, admin can add multiple offline
                    services like Pan card, water bill, etc.
                  </li>
                  <li>
                    Major services like registration, fund credit/debit, balance
                    enquiry, complaint placing can be done through
                    portal/web/SMS
                  </li>
                  <li>Instant fund reversal for failure recharges</li>
                  <li>
                    We can integrate multiple API’s of recharge, bulk SMS in our
                    software according to client choice.
                  </li>
                  <li>
                    Admin have full control to switch recharge API according to
                    operator or API wise instantly
                  </li>
                  <li>
                    admin can add unlimited members (master distributors
                    /distriburs/retailers) in its panel and can upgrade the
                    menbers
                  </li>
                  <li>Fast search result and proper reporting features</li>
                  <li>
                    Accounting module for getting accurate profit and loss
                    everyday
                  </li>
                  <li>GST reporting features</li>
                  <li>Connected with secure database</li>
                  <li>Eye catching website design</li>
                  <li>User friendly admin and member panel</li>
                  <li>
                    Admin have privilege to change its member configurations and
                    actions
                  </li>
                  <li>
                    Auto fetch operator API integrated in the recharge system
                  </li>
                  <li>Integrate any LAPU device in our Software.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container" data-aos="flip-left">
            <div className="row finetech-page-row">
              <h1 className="text-center my-5">Bharat Bill Payment System(BBPS)</h1>

              <div className="col-sm-12 col-xl-6">
                <p className="pt-4">
                  Bharat Bill Payment System is an online bill payment system
                  offering all kind of day to day bill payments instantly.
                </p>
                <p>
                  BBPS is regulated by National Payments Corporation of India
                  (NPCI) and conceptualized by Reserve Bank Of India, also
                  expanded the scope of BBPS. NPCI is a not-for-profit
                  organization which is accepted all over India. It is
                  responsible for operating retails payments and settlement
                  systems in India.
                </p>
                <p>
                  BBPS offers instant confirmation of payment which can be done
                  by multiple payment modes used in India. Bharat Bill Pay
                  Service is a safe and reliable payment system providing
                  anytime, anywhere access to everyone with universal access to
                  all payment system.
                </p>
                <p>
                  It is an interoperating bill payment service to various
                  customers at different places through a network of agents. A
                  particular amount of commission is earned by agents on every
                  payment done via BBPS portal.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot} alt=""  style={{width:'100%',height:'70vh'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid finetech-page">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <h1 className="text-center my-4">
                Aadhaar Enabled Payment System (AEPS)
              </h1>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',justifyContent:'center'}}>
                <img src={Screenshot1} alt="" className="img-fluid"  style={{width:'100%',height:'70vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  Aadhaar enabled payment system (AEPS) is a new payment system
                  which is basically based on Aadhaar card number and enables
                  customer for cashless transaction. AEPS helps customers to
                  perform basic banking transaction including fund transfer,
                  cash deposit, cash withdrawal, balance enquiry and mini
                  statement. This was the first initiative towards cashless
                  society from Indian government.
                </p>
                <p>
                  This project was initialized in January, 2016 when Indian
                  government linked all bank accounts with individual Aadhaar
                  card numbers as unique identity.
                </p>
                <p>
                  AEPS facilitate us to perform basic bank transactions without
                  visiting any ATM or bank. All you need is your Aadhaar card
                  linked with your respective bank account.
                </p>
                <p>
                  Aadhaar card shows your unique identity and verifies your
                  finger print impression which works as a pin. There is no need
                  to remember any pin code like in case of debit or credit
                  card.So this was a brief about AEPS, Let’s discuss it in more
                  detail. Keep reading for interesting knowledge about AEPS
                  which can be your upcoming business start up idea.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid mt-5">
          <div className="container">
            <div className="row finetech-page-row">
              <h1 className="text-center">Money Transfer Softwere</h1>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot2} alt="" style={{width:'100%',height:'70vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  Money transfer is a cashless mode of payment from one bank
                  account to another. We provide money transfer software which
                  is comprehensive and fully featured solution. It is a safest
                  mode of transferring your money.
                </p>
                <p className="pt-2">
                  <span>Maxway Infotech </span>offers easy Domestic money
                  transfer software to transfer money from main wallet to bank
                  account. You are just one click away from money remittance. We
                  aim at streamlining your business process to enhance your
                  business growth and vision. Our domestic money transfer
                  software provides you a perfect environment to get your Money
                  Transfer business going instantly and grow it rapidly. Getting
                  your money remittance business running can be tricky for you.
                  We are here to help you by providing a unique and advanced
                  technology and services.
                </p>
                <p>
                  There are some other things to focus such as strategy,
                  customer acquisition and your customers. The money transfer
                  software solution offered by <span>Maxway Infotech </span>
                  helps customers to transfer the fund easily from main wallet
                  to bank account. Nowadays customers find money transfer online
                  easy because of instant and secure money transfer.
                </p>
              </div>
              <div className="col-12">
                <p>
                  &nbsp;When customer has the online mode of money transfer,
                  they don’t have to waste their time in the long queues in
                  banks and other money transfer offices. We offer money
                  transfer software which is helpful for you to begin a business
                  that simplifies customer’s life. Along with perfect money
                  transfer software, we also provide online money transfer app
                  for easy access.
                </p>
                <p>
                  We offer trusted money transfer solution that support both
                  IMPS and NEFT mode. The main feature of our software is that
                  you can add beneficiary for trusted money transfer. We have
                  already helped a lot of businesses to start their own money
                  transfer business successfully. Our professional software
                  developers understand the usage of money transfer API and use
                  them properly to help you in building a platform for
                  maintaining a professional business. We are proven company for
                  providing the most accurate money transfer software solution
                  in India
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid finetech-page">
          <div className="container">
            <div className="row my-5">
              <h1 className="text-center my-3">Pan Card</h1>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot3} alt="" className="img-fluid" style={{width:'100%',height:'90vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  PAN (Permanent Account Number) is a unique identification
                  number assigned to all the taxpayers in India. It is a 10
                  digit alphanumeric number in the form of a card, issued by the
                  Income Tax Department in India.
                </p>
                <p>
                  The main purpose of PAN Card is to link all the transaction of
                  the person or a company with the income tax department. PAN
                  Card is issued to anyone who pays taxes in India including
                  individuals, companies, non-residents, etc. No two taxpaying
                  entities can have the same PAN Number.
                </p>
                <p>
                  <h4 className="pb-3">
                    Documents required for PAN Card Application
                  </h4>
                </p>
                <p>
                  A customer needs to submit the required documents along with
                  the PAN Card application form. The documents are scanned by
                  the agent to send it further. The documents include
                </p>
                <p>
                  <h5 className="fw-bold">
                    2 recent passport size color photographs
                  </h5>
                </p>
                <p>
                  <h5 className="fw-bold">Proof of ID</h5>
                </p>
                <p>
                  <h5 className="fw-bold">Permanent Address</h5>
                </p>
                <p>
                  <h5 className="fw-bold">Date of Birth</h5>
                </p>
                <p>
                  <h5 className="fw-bold">Applicable Fee</h5>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row finetech-page-row">
              <h1 className="py-4 text-center">UPI Collection</h1>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot4} alt="" style={{width:'100%',height:'50vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  The role of technology in finance is helping people for easy,
                  fast and secure banking. Now people are using mobile banking
                  for processing transactions. In true manner now India is going
                  ahead towards cashless society. In the path of digital India,
                  UPI payment collection was another biggest achievement.
                </p>
                <p>
                  So If you belong to the fintech industry and working as an
                  admin then this article is very beneficial for you. Here I
                  will explain to you, how you can provide your own brand QR
                  code to your members and can accept payment like
                  <span>&nbsp;</span>
                  <a href="https://paytm.com/" target="" rel="noopener">
                    PayTm
                  </a>
                  , PhonePe, GooglePay etc. Along with these big giants, you can
                  also provide your own brand QR code facility to your members
                  and can directly allow customers to pay through UPI address or
                  QR code. This will also help you to expand your fintech
                  services and brand value in the market. Is it exciting? So
                  without wasting time, I come to the point.
                </p>
              </div>
              <div className="col-12">
                <h1>What is UPI Payment Collection Service?</h1>
                <p>
                  UPI full form is a unified payment interface. This is the
                  latest mode of accepting payment in India. UPI allows
                  customers to pay into beneficiary account by multiple bank
                  accounts in a single application. In short, by using UPI, you
                  don’t have to switch on various bank mobile applications; you
                  can integrate all banks in a single UPI and can pay.
                </p>
                <p>
                  To make an easy, fast and secure payment accepting facility
                  Maxway Infotech has developed a unique UPI payment collection
                  service.
                </p>
                <p>
                  By using this UPI payment collection service, members can
                  generate their own unique QR code and can paste it on shop.
                </p>
                <p>
                  Customers can directly pay using this UPI address or QR code.
                  This payment directly comes in<span>&nbsp;</span>AEPS wallet
                  <span>&nbsp;</span>that you can further transfer to either
                  main wallet, or bank or can use as money transfer through
                  express payout.
                </p>

                <h2>Difference Between UPI PoS and UPI Payment Collection?</h2>
                <div className="col-12" data-aos="zoom-out-down">
                  <table>
                    <tr>
                      <th>UPI Pos (UPI Collection)</th>
                      <th>UPI Payment Collection</th>
                    </tr>
                    <tr>
                      <td>You can accept 1000 rupess per account.</td>
                      <td>You can accept an unlimited amount</td>
                    </tr>
                    <tr>
                      <td>Earn commission on every transaction.</td>
                      <td>Pay surcharge for each transaction. ()Lowest</td>
                    </tr>
                    <tr>
                      <td>
                        You have to generate a new QR code for every
                        transaction.
                      </td>
                      <td>Single QR code use for accepting payment.</td>
                    </tr>
                  </table>
                </div>
                <div className="col-12">
                  <p className="my-3">
                    So these are a few key differences between UPI collection
                    and UPI payment collection service. As a member, you can
                    accept payments using<span>&nbsp;</span>UPI PoS (UPI
                    Collection)<span>&nbsp;</span>under 1000 rupees whereas for
                    more than 1000 rupees you can use UPI payment collection
                    service.
                  </p>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-xl-6 mt-5">
                    <h4>Benefits of Maxway Infotech UPI Collection Service</h4>
                    <ul className="fs-5">
                      <li className="pb-1">Accept payment seamlessly</li>
                      <li className="pb-1">Highly secured and fast payment</li>
                      <li className="pb-1">Accept all bank payments</li>
                      <li className="pb-1">24*7 active</li>
                      <li className="pb-1">100% success ratio</li>
                      <li className="pb-1">Accept payment at lowest surcharge</li>
                      <li className="pb-1">
                        Boost your brand value and customer base
                      </li>
                      <li className="pb-1">
                        More service, more profit
                        <li className="ps-2 ms-3 pb-1">
                          Boost your brand value and customer base
                        </li>
                      </li>
                      <li className="pb-1">
                        No need of bank account no. and IFSC code
                      </li>
                      <li className="pb-1">Unique Identity ID for every members</li>
                      <li className="pb-1">Lifetime free technical support</li>
                      <li className="pb-1">
                        We provide you manual training to use it
                      </li>
                    </ul>
                    <p className="my-2">
                      So these are all the benefits of using the UPI payment
                      collection service.
                    </p>
                  </div>
                  <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                    <img src={Screenshot5} alt="" className="img-fluid" style={{width:'100%',height:'60vh'}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid finetech-page">
          <div className="container">
            <div className="row py-5">
              <h1>Prepaid Card</h1>
              <div className="col-12">
                <p>Prepaid cards are introduced as a smart alternative to cash, debit cards and credit cards. You can take them along with you anywhere and spend only what you have already deposited in your card.</p>
                <p>There is no need for a bank account to apply for a prepaid card. You can reload it with cash anytime whenever you need. These cards are issued by retailers and are available in different range according to the customer’s need.</p>
              </div>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot6} alt="" className="img-fluid"  style={{width:'100%',height:'100vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>As a credit card alternative prepaid cards are issued to the customers having poor credit history or no credit history.</p>
                <p>You can even withdraw cash using a prepaid card at any ATM, not all the banks allow customers to withdraw cash but some banks allow cash withdrawal through prepaid cards.</p>
                <p>You can enjoy a several benefits of a Prepaid Card. Easy to use and a secure process without any need of bank account.</p>
                <ul>
                  <li className="mb-4">There is no need of a bank account</li>
                  <li className="mb-4">Can be issued to anyone after KYC</li>
                  <li className="mb-4">Can be reloaded anytime with cash</li>
                  <li className="mb-4">No need to visit bank for a prepaid card</li>
                  <li className="mb-4">Can be issued by any retailer having the software</li>
                  <li className="mb-4">Money can be withdrawn from any ATM</li>
                  <li className="mb-4">Promoting Cashless society</li>
                  <li className="mb-4">Can be swiped anywhere</li>
                  <li className="mb-4">Attractive commission can be earned by the retailers on every online transaction</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row my-4 finetech-page-row">
              <div className="col-12">
                <h1 className="text-center">Aadhaar Pay </h1>
                <p className="pt-4 pb-4">Aadhaar enabled payment system (AEPS) is a new payment system which is basically based on Aadhaar card number and enables customer for cashless transaction. AEPS helps customers to perform basic banking transaction including fund transfer, cash deposit, cash withdrawal, balance enquiry and mini statement. This was the first initiative towards cashless society from Indian government.</p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={Screenshot7} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <p className="pt-5">This project was initialized in January, 2016 when Indian government linked all bank accounts with individual Aadhaar card numbers as unique identity.</p>
                <p>AEPS facilitate us to perform basic bank transactions without visiting any ATM or bank. All you need is your Aadhaar card linked with your respective bank account.</p>
                <p>Aadhaar card shows your unique identity and verifies your finger print impression which works as a pin. There is no need to remember any pin code like in case of debit or credit card.So this was a brief about AEPS, Let’s discuss it in more detail. Keep reading for interesting knowledge about AEPS which can be your upcoming business start up idea.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid my-5 finetech-page">
          <div className="container">
            <div className="row py-5">
              <h1 className="text-center py-4">mATM & mPOS</h1>
              <div className="col-12">
                <p className="pt-4 pb-4">mPOS (Mobile POS) refers to mobile point of sale system which is the combination of software and hardware. This is an electronics device used by merchants for transaction in daily business life.</p>
                <p><span>Nowadays mPOS machine is going to be key element in businesses just because of its cashless transaction function.Basically it is used in retail shops where customer likes perform transactions through Credit or Debit card. This is basically used in retail shop, grocery, food and beverage industry.</span></p>
                <p><span>I think till now you have understood for what machine talking about. Even if you have doubt I give an example which helps you to understand this clearly.</span></p>
                <p><span>Have you ever go to malls for shopping, or MacDonald’s for food, whenever you don’t have cash money, what you do? You preferred your credit or debit card for payment. Am I Right?</span></p>
                <p><span>And the shopkeeper or seller swipes your card through a small machine and you enter your pin code for complete payment.</span></p>
              </div>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot8} alt="" className="img-fluid" style={{width:'100%',height:'60vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p><span>I am definitely sure most of you experienced this, this small machine is known as mPOS machine and this whole process is known as mPOS payment system.</span></p>
                <p><span>So now you are very familiar with mPOS machine, but did you ever think how this machine works and hoe payment process?</span></p>
                <p><span>Any question arises in your mind now?</span></p>
                <p><span>Yes sure, don’t worry, now we will discuss about this topic, so keep tuning with us till end for more informative knowledge.</span></p>
                <p><span>We all used mPOS machine in grocery shop and malls for payment. But we never think how this mPOS machine works? How our payment settled?</span></p>
                <p><span>Without wasting time I come to the point after all how this works. As we know mPOS machine is the combination of software and hardware and its software part is integrated with seller’s bank account.</span></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row finetech-page-row">
              <h1 className="text-center py-5">Hotel Booking Softwere</h1>
              <div className="col-12">
                <p><span>Maxway Infotech </span>hotel booking software is one stop solution for all domestic and international online hotel bookings. If you are in the field of travel industry and want to add on hotel booking system in your travel portal then this article may be helpful for you.</p>
                <p>Today, we know most of the travellers need to visit travel agents to book bus, flight, hotel tickets. This is the great opportunity for all the travel agents if they want to start their own multi services under a single roof.</p>
                <p>By using our hotel booking portal you can offer all domestic and international hotel bookings and can earn the highest commission in the market.</p>
                <p>Our <span>Maxway Infotech </span>hotel booking engine is fully customized and based on latest technology. Our skilled and experienced developer’s team created a user-friendly and responsive portal which is easy to use.</p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>Its interface is very flexible which will help end user to use it very smoothly.</p>
                <p><span>Maxway Infotech </span>hotel booking portal allows you to manage hotel inventory, reservations, room rates, policies, multi search filters and wide range of hotel availability all over the world.</p>
                <p>We offer you B2B, B2C hotel booking portal with integrated API solution. By using this hotel booking portal you can create travel agent all over India and can earn attractive commission.</p>
                <p>Along with hotel booking portal we offer you free hotel booking app which you can upload on play store and can increase the visibility of your brand.</p>
                <p>Let’s discuss about B2B and B2C hotel booking portal and their working. We will also learn about how you can start your own hotel booking portal at your small retail shop and can earn the highest commission without investing lots of money.</p>
                <p>So keep tuning with us till the end if you are really interested to start and earn money</p>
              </div>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot9} alt="" className="img-fluid" style={{width:'100%',height:'60vh'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid finetech-page">
          <div className="container">
            <div className="row">
              <h1 className="text-center py-5">Flight Booking Softwere</h1>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot10} alt="" className="img-fluid" style={{width:'100%',height:'70vh'}}/>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>Planning to start your own travel portal and looking for best flight ticket booking software Provider Company then you are at right place. Here I will tell you what travel portal is and how you can start your own travel portal low investment.</p>
                <p>Travel portal is a type of software offering one stop solution for all travel services. It provides online platform to travel industry so that they can setup their business online. This is online booking engine facility that allows you to book flight tickets online on both B2B and B2C platforms.</p>
                <p>B2B travels portal stands for business to business online booking engine. In B2B travel portal you can offer service to other businesses and can create as many agents as you want.</p>
                <p>B2C travel portal stands for business to customers where you offer flight booking services directly to the customers without any intermediate.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row finetech-page-row">
              <h1 className="text-center py-5">Bus Booking Softwere</h1>
              <div className="col-12">
                <p><span>Maxway Infotech </span>provide real time bus ticket booking software solution for travel agencies. We have designed a comprehensive online bus booking system s per client’s requirements that would save both time and money.</p>
                <p><span>Maxway Infotech provide real time bus ticket booking software solution for travel agencies. We have designed a comprehensive online bus booking system s per client’s requirements that would save both time and money.</span></p>
                <p><span>Our bus booking software has all the updated features including e-ticket booking, booking cancellation, advance booking with expected dates, etc. Online bus booking system allows you to manage reservations, traveler details and schedule routes, set seat availability, etc.</span></p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <p><span>Our bus booking software is designed to automate the online ticketing through an easy to use online bus booking system. The client can reach the vast number of customers with the help of online booking application.</span></p>
                <p><span>Tourist can book their tickets from anywhere, even out of country. Administrator can manage the trip details and can cancel the tickets and approve the cancellation requests anytime. Client can also take the printout of the ticket whenever he wants.</span></p>
                <p><span>We are providing world className travel service for our clients from the last few years. You can earn the best commission ever on every online bus booking. Our support team always helps you in achieving success and business growth.</span></p>
                <p><span>We provide both B2B and B2C software services to our clients as per their requirements. B2B bus booking software allows partners or travel agents to book bus tickets for various routes and destination on their own travel agency from the system.</span></p>
                <p><span>B2C bus booking software is a complete bus booking application that allows visitors to book bus ticket for various routes and destination by making online payment.</span></p>
              </div>
              <div className="col-sm-12 col-xl-6" style={{display:'flex',alignItems:'center'}}>
                <img src={Screenshot11} alt="" className="img-fluid" style={{width:'100%',height:'80vh'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FinetechSoftwere;
