import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import WindowTop from "./WindowTop";
import GotoTop from "./GotoTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";
import TeenPattiGame from "./BlogPage/TeenPattiGame";

const Blog = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <PageLoading type={'Blogs'} />
      <Header />
      <TeenPattiGame />
      <Footer />
    </>
  );
};

export default Blog;
