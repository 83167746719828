import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgSlider1 from "../image/Client_1_maxpaper_filter.webp";
import imgSlider2 from "../image/Client_2_laborator_filter.webp";
import imgSlider3 from "../image/Client_3_honeyhosting_filter.webp";
import imgSlider4 from "../image/Client_4_cryptonite_filter.webp";
import imgSlider5 from "../image/Client_5_dynamic_filter.webp";
import imgSlider6 from "../image/lient_6_deters_filter-1 (1).webp";

const Carosal = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <section>
        <div className="container my-5 py-5">
          <div>
            <Slider {...settings}>
              <div>
                <img src={imgSlider1} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider2} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider3} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider4} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider5} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider6} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider1} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider2} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider3} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider4} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider5} className="img-fluid" alt="" />
              </div>
              <div>
                <img src={imgSlider6} className="img-fluid" alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Carosal;
