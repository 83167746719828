import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import aboutImg from "../image/what_we_do_and_how_banner.webp";
import cricket from "../image/cricket1.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import 'animate.css';
import PageLoading from './PageLoading';

const About = () => {
  useEffect(() => {
    AOS.init();

  }, [])
  return (
    <>
      <PageLoading type = {About} />
   
      <WindowTop />
    
      <Header />
      <section className="about-pages" data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000">
        <div className="container">
          <div className="row about-row">
            <h1> About Us</h1>
          </div>
        </div>
        <section>
          <div className="container-fluid my-4 ">
            <div className="row p-4">
              <div>
                <h2>Who We Are ? </h2>
                <p>
                  Welcome to Maxway Infotech – Your Trusted IT Partner in
                  Jaipur, Rajasthan, Serving Clients Across India, the US, and
                  the UK!
                </p>
                <p>
                  At Maxway Infotech, we are committed to delivering excellence
                  and innovation in the world of technology. As a leading IT
                  company based in Jaipur, Rajasthan, we offer a wide array of
                  services, including Web Development, App Development, Game
                  Development, and Digital Marketing. Our team of experts
                  comprises skilled professionals, including business analysts,
                  software architects, database experts, programmers, testers,
                  and project managers. Together, we are dedicated to helping
                  businesses achieve their goals with top-notch solutions.
                </p>
              </div>
              <div>
                <h2>Our Services :</h2>
                <p>
                  <strong>Web Development:</strong> Unleash the power of the
                  internet with our bespoke web development solutions. We craft
                  stunning, user-centric websites that create a lasting
                  impression and drive business growth.
                </p>
                <p>
                  <strong>App Development:</strong> Stay ahead of the curve with
                  our cutting-edge mobile applications. From intuitive UI/UX
                  design to robust functionality, our apps are designed to
                  captivate and engage users.
                </p>
                <p>
                  <strong>Game Development:</strong> Engage your audience in immersive interactive adventures with our expertise in game development. Our skilled team crafts dynamic and entertaining experiences for diverse platforms.s.
                </p>
                <p>
                  <strong>Digital Marketing:</strong> Maximize your online
                  presence with our result-driven digital marketing strategies.
                  From SEO and social media to content marketing, we ensure your
                  brand reaches the right audience.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className="container-fluid about-img" data-aos="fade-down-right">
          <img src={aboutImg} alt="" />
        </div>
      </section>
      <section className="about-pages animate__animated animate__rubberBand">
        <div className="container ">
          <div className="row">
            <h2>Why Choose Maxway Infotech?</h2>
            <p>✔ Experience: With 9 years of experience in the industry, we have honed our skills to perfection. Trust our expertise to steer your projects towards success.</p>
            <p>✔ Skilled Professionals: Our team of experts brings diverse talents and capabilities to the table, ensuring you get top-quality solutions tailored to your specific needs.</p>
            <p>✔ Reliable Delivery Framework: We follow a robust delivery framework that guarantees consistency and minimizes obstacles, helping you meet challenging deadlines efficiently.</p>
            <p>✔ Client-Centric Approach: Your business goals and vision are at the heart of everything we do. We work collaboratively to turn your ideas into reality.</p>
            <p>✔ Cost-Effectiveness: We believe in offering value for money. Our solutions are competitively priced without compromising on quality.</p>
            <p>If you are seeking innovative IT solutions to drive your business forward, Maxway Infotech is here to partner with you! Connect with us to explore how our expertise can take your business to new heights.</p>
            <p>Our services extend across India, the US, and the UK, with our company located in Jaipur, Rajasthan.</p>
          </div>
          <div className="container">
            <div className="row">
              <h2>Our Mission</h2>
              <p>Our mission is to enhance business growth of our clients with creative designs and development. We understand the requirement of our client, analyze it and provide the best solutions. We believe in client satisfaction by our services and implement it with more effective software solution strategies. We always believe in delivering innovative and reliable solutions to our clients with utmost quality and resolved ethics. Being a professional web and software development company in India, we provide customer-centric, result-oriented, cost-competitive, innovative and functional software and IT solutions to our valuable global clients. We stick to some principles in delivering our mission. Also we understand the needs and requirements of our customers and provide solutions based on latest technology to resolve the needs. Maxway Infotech believes to explore new opportunities that maximize the business value, consistent growth, and sustainability. Our professional team of expert posses outstanding talent that can help to accelerate your organization. We empower our clients to respond faster to changing market dynamics. Also, we enable them to become more agile and competitive through leveraging new technologies.</p>
            </div>
          </div>
        </div>

      </section>
      <section>
        <div className="container-fluid about-img " data-aos="flip-right">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xl-6 pt-5">
                <h1>Fantasy Cricket App Development Company</h1>
                <p className="fs-5" >Maxway Infotech is committed to delivering high-quality fantasy cricket app solutions that engage users, drive participation, and generate substantial returns on investment. Our experienced team stays updated with the latest trends and technologies in the industry to provide innovative and future-ready solutions.</p>
                <p className="fs-5">Partner with us to transform your fantasy cricket app idea into a reality. Contact Maxway Infotech today to discuss your fantasy cricket app development requirements, and let us help you create a remarkable platform that captures the essence of the game development and delights cricket enthusiasts worldwide.</p>
                <button> <Link className="hLink" to="/*">READ MORE</Link></button>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={cricket} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <GotoTop />
      <WhatsappFixed />
    </>
  );
};

export default About;
