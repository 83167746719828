import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import androidImg from "../image/Screenshot-2023-09-27-153656.webp";
import androidImg1 from "../image/online-fashion-shopping-with-laptop-2048x1367.webp";
import androidImg2 from "../image/Screenshot-2023-09-27-153940.webp";
import androidImg3 from "../image/Screenshot-2023-09-27-153836.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";


const AndroidApp = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='Android App Development' />

      <section>
        <h1 className="text-center fs-1 my-5">Android App Development</h1>
        <div className="container-fluid android-page">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <h2 className="my-3 weight700">
                  Mobile Application Development Company, Android, iOS App
                  Development
                </h2>
                <p>
                  Our skilled team of developers is dedicated to provide you the
                  best mobile app development service. They engineered to bring
                  growth to your business by creating innovative applications.
                </p>
                <p>
                  We never compromise with time and quality and deliver the high
                  quality applications on time. Devices that rely on apps have
                  become popular all over the world. With the help of mobile
                  apps, you can tap into the infinite market and expand your
                  reach as well as boost your growth in a short period of time.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={androidImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid android-page1">
          <div className="container">
            <div className="row p-5">
              <div className="col-sm-12 col-xl-6">
                <img src={androidImg1} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6 text-light">
                <h2 className=" fs-1">
                  We Deliver Memorable E-Commerce Website and App development
                </h2>
                <p className="pe-4">
                  Give your users a seamless experience by testing on 3000+ real
                  devices and browsers. Don’t compromise with emulators and
                  simulators
                </p>
                <button> <Link className="hLink" to="/android">ORDER NOW</Link></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid android-page3">
          <div className="container my-5">
            <p>
              Our professional experts understand the importance of graphic
              design on mobile applications. We make sure our every app is
              visually attractive, easy to use and can be utilized by large
              number of people. We are expert in creating mobile apps of IOS and
              Android with user attractive UI design and functionality. Mobile
              applications are the most important factor for many businesses.
            </p>
            <p>
              Apps running on smart devices give access to company-related
              resources and give chance to acquire more business opportunities.
              Mobile applications are a best way to get into the digital market
              for its internal operations and customer- facing services. Apps
              function on different methods and we have the skills to build apps
              that are accessible on all android device whether it’s a
              Smartphone or a tablet.
            </p>
            <p>
              We are specialized in both multi-platform synchronization and
              standalone mobile application creation on IOS and Android. Multi
              platform apps can exist in any smart-device ecosystem. There’s no
              need to limit yourself to a specific market having a specific
              device.
            </p>
            <p>
              Being the best mobile app development company in Jaipur, we
              provide best-in-className mobile app development service that will
              retain the attention of the target audience. Our developers build
              beautiful mobile app design to attract users and to keep you ahead
              of users and business demand.
            </p>
            <p>
              We have a well-planned and strategic approach to deliver an
              outstanding work. We stay connected with our clients at every step
              of the mobile app development process to deliver the expected
              result. This is why we have higher client satisfaction rate and
              more happy clients.
            </p>
            <p>
              The idea of mobile app designing is the first process towards the
              user’s interest. For this purpose, we have finest designers to
              create best user interface. We design applications with amazing
              user experience that develops user’s interest in your app.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid android-page4">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-xl-6">
                <img src={androidImg2} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <p>
                  Our advanced Android App development process has helped us to
                  immerse deep into the android apps world. This is the process
                  we follow to develop applications at Maxway Infotech
                </p>
                <ul>
                  <li>Understanding customer’s need</li>
                  <li>UIUX Design</li>
                  <li>App Designing</li>
                  <li>App Development</li>
                  <li>Quality Assurance</li>
                  <li>Play Store Submission</li>
                  <li>Post Launch</li>
                  <li>On Going Support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container-fluid android-page-5">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6 ">
                <h2 className="text-center py-2 my-2">
                  Our Wide Range Of Android App Development Service
                </h2>
                <ul>
                  <li>Fantasy Sports App Development</li>
                  <li>Grocery App Development</li>
                  <li>Taxi Booking App Development</li>
                  <li>Food Deliver App Development</li>
                  <li>On-Demand Game App Development</li>
                  <li>Online Education App Development</li>
                  <li>Home Service App Development</li>
                  <li>Hotel Booking App development</li>
                  <li>education portal App development</li>
                  <li>Credit card App development</li>
                  <li>Business Management App Development</li>
                  <li>News Blogs App Development</li>
                  <li>Movies Blogs App Development</li>
                </ul>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={androidImg3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AndroidApp;
