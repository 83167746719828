import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './Componet/Homepage/Home';
import About from './Componet/About';
import Gaming from './Componet/Gaming';
import FinetechSoftwere from './Componet/FinetechSoftwere';
import Blog from './Componet/Blog';
import FantesyGame from './Componet/FantesyGame';
import ColorGame from './Componet/ColorGame';
import Website from './Componet/Website';
import AndroidApp from './Componet/AndroidApp';
import DigitalMarketing from './Componet/DigitalMarketing';
import BulkWhatsapp from './Componet/BulkWhatsapp';
import BulkSms from './Componet/BulkSms';
import Contact from './Componet/Contact';
import ErrorPage from './Componet/ErrorPage';
import { Thankyou } from './Componet/Thankyou';

function App() {
  let routes = createBrowserRouter([
    {
      path: '/',
      element: <Home/>
    },
    {
      path: '/about',
      element: <About/>
    },
    {
      path:'/game-development',
      element: <Gaming />
    },
    {
      path:'/finetech',
      element: <FinetechSoftwere />
    },
    {
      path:'/blog',
      element: <Blog />
    },
    {
      path:'/fantesy',
      element: <FantesyGame />
    },
    {
      path:'/color',
      element: <ColorGame />
    },
    {
      path:'/website',
      element: <Website/>
    },
    {
      path:'/android',
      element: <AndroidApp />
    },
    {
      path:'/digital',
      element: <DigitalMarketing />
    },
    {
      path:'/whatsapp',
      element: <BulkWhatsapp />
    },
    {
      path:'/sms',
      element: <BulkSms />
    },
    {
      path:'/contact',
      element: <Contact />
    },
    {
      path:'*',
      element: <ErrorPage />
  
    },
    {
     path:'/thank-you',
     element:<Thankyou/>
    }
  
  ]);
  
  return (
    <>
    <RouterProvider router={routes} />
    </>
  );
}


export default App;
