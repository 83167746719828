import React, { useEffect } from 'react'
import newImg from '../image/Screenshot-2023-09-28-184923.webp'
import { BsBoxArrowLeft } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
export const Thankyou = () => {
  const nevigate  = useNavigate();
  useEffect(()=>{
  setTimeout(()=>{
  nevigate('/')
  },3000)
  },[])
  return (
    <>
        <div style={{height:'100vh',width:'100%'}}>
       <div style={{position:'relative'}}>
         <img src={newImg} alt='' style={{width:'100%',height:'100vh'}}/>
          <div style={{position:'absolute',top:'5%',left:'3%'}}>
              <Link to='/'> <h4><BsBoxArrowLeft/><span style={{fontSize:'12px',fontWeight:'bold'}}>BACK</span></h4></Link>
          </div>
            <div className='thanks' style={{}}> 
                  <h4 style={{color:'#146cba'}}>Thank you for submitting!</h4>
              </div> 
         </div>
           
        </div>
    </>
  )
}
