import React from 'react';
import { Link } from "react-router-dom";

const WhatsappFixed = () => {
  const phoneNumber = "9116672785"; // Replace with your phone number including country code
  const openWhatsApp = () => {
    // const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    let url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
};
  return (
    <>
    <div className="whatsappFixed">
        <button  onClick={openWhatsApp} style={{background:'none'}}><i className="fa-brands fa-whatsapp"></i></button>
    </div>

    </>
  )
}

export default WhatsappFixed;