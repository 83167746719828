import React from 'react'
// import Slide1Table from '../image/imagesGame/Slide1Table.webp'
// import slide3 from '../image/imagesGame/slide4.webp'
// import slide2Table from '../image/imagesGame/slide2table.jpeg'
//  import slidestatic from '../image/imagesGame/slidestatic.jpg'
// import slide8 from '../image/imagesGame/slide8.webp'
import slideGaming1 from '../image/imagesGame/slideGaming1.webp'
import slideGaming4 from '../image/imagesGame/slideGaming4.webp'
import slideGaming3 from '../image/imagesGame/slideGaming3.webp'




export const SliderGaming = () => {
  return (
    <div className='row p-3' style={{width:'100%',marginTop:'40px'}}>
    <div className='col-lg-6 col-sm-12 mt-3' >
    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000">
      <img  src={slideGaming1} class="d-block w-100 slideTable" alt="..." />
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img  src={slideGaming4} class="d-block w-100 slideTable" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img  src={slideGaming3} class="d-block w-100 slideTable" alt="..."/>
    </div>
  </div>

</div>
    </div>
    <div className='col-lg-6 col-sm-12'>
    <div className='p-1' style={{background:'#e3eeff',borderRadius:'5px'}}>
    
      <p><span style={{fontWeight:'bold'}}>Welcome to a realm</span> where excitement knows no bounds and fortunes await at every turn. At our exquisite casino, indulge in a dazzling array of games that will thrill your senses and captivate your imagination.
<br></br>
<span style={{fontWeight:'bold'}}>Roulette:</span> Feel the anticipation as the ball spins round the wheel, daring you to bet on where it will land.
<br></br>
<span style={{fontWeight:'bold'}}>Blackjack:</span> Challenge the dealer in a battle of wits and nerve. Can you hit 21 and beat the house?
<br></br>
<span style={{fontWeight:'bold'}}>Slots:</span> Enter a universe of spinning reels and endless possibilities. Jackpots beckon – will you be the next big winner?
<br></br>
<span style={{fontWeight:'bold'}}>Poker:</span> Test your poker face and strategic prowess in the ultimate card game of skill and chance. Bluff, raise, and dominate the table.
<br></br>
<span style={{fontWeight:'bold'}}>Baccarat:</span>Embrace the elegance of this classic game of chance. Will you back the player, the banker, or will it be a tie?

</p>
    
    </div>
    </div>
     </div>
  )
}
