import React from 'react';
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";

const Faq = () => {
    return (
        <>
        <div className="text-center my-5">
            <h1>FAQ</h1>
        </div>
            <MDBAccordion flush initialActive={0}>
                <MDBAccordionItem collapseId={1} headerTitle='What is Game Development?'>
                    Game development involves creating action, role-playing, sports, strategy, casino, and other genres for web, PC, mobile (Android, iOs), cloud, and console platforms (Xbox, Nintendo, PS4). Game development requires programming, designing, releasing, and testing.
                </MDBAccordionItem>
                <MDBAccordionItem collapseId={2} headerTitle='Who will manage my project?'>
                    Expand My Business takes ownership of the project and provides end-to-end management for the same.Every project is assigned to a key account manager or consultant who ensures that the project is delivered on time and that all project expectations have been met. Also, they will check whether or not the vendor has adhered to all terms of the agreement specified in the contract.
                </MDBAccordionItem>
                <MDBAccordionItem collapseId={3} headerTitle='How EMB secures my payment?'>
                    Escrow Solution by Expand My Business help in securing your transactions. In order to ensure the utmost payment security the money received by the clients is placed in an escrow account. We work with the partner agency on a milestone basis. The project is divided into different milestones. Upon the completion of a particular milestone, the work is sent to clients for approval. Once the client approves the work, the payment is released to the partner agency for the particular milestone. This help us regulate the payment cycle while at the same time safeguarding transactions.
                </MDBAccordionItem>
                <MDBAccordionItem collapseId={4} headerTitle='How can I track my project?'>
                    Our Key Account Managers or Delivery Managers keep the clients in the loop about the project. Client Approval is needed for them to go forward to the next milestone, when the client gives the heads up, then only the payment is released.
                </MDBAccordionItem>
            </MDBAccordion>
        </>
    )
}

export default Faq