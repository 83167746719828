import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { GoNorthStar } from "react-icons/go";
import { useNavigate } from "react-router-dom";

const PageLoading = ({ type }) => {
  useEffect(()=>{

  },[] )

  let HidePopup= ()=>{
    let windoShow=document.querySelector('.windoShow');
    windoShow.classList.add('windoShowss');
 }
  const initialState = {
    Name: '',
    Email: '',
    CountryCode: '',
    Phone: '',
    WPhone: '',
    Catagory: `${type}`,
  };

  const [formData, setFormData] = useState(initialState);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, CountryCode: e.value });
    }
  };

  const countryOptions = [
    { value: '+1', label: 'United States (+1)' },
    { value: '+91', label: 'India (+91)' },
    { value: '+44', label: 'United Kingdom (+44)' },
    { value: '+33', label: ' France (+33)' },
    { value: '+49', label: 'Germany (+49)' },
    { value: '+81', label: ' Japan (+81)' },
    { value: '+1', label: 'Canada (+1)' },
    { value: '+61', label: 'Australia (+61)' },
    { value: '+39', label: 'Italy (+39)' },
    { value: '+34', label: 'Spain (+34)' },
    { value: '+86', label: 'China (+86)' },
    { value: '+55', label: 'Brazil (+55)' },
    { value: '+52', label: 'Mexico (+52)' },
    { value: '+7', label: 'Russia (+7)' },
    { value: '+966', label: 'Saudi Arabia (+966)' },
    { value: '+27', label: 'South Africa (+27)' },
    { value: '+971', label: 'United Arab Emirates (+971)' },
    { value: '+65', label: 'Singapore (+65)' },
    { value: '+82', label: 'South Korea (+82)' },
    { value: '+62', label: 'Indonesia (+62)' },
    { value: '+31', label: 'Netherlands (+31)' },
    { value: '+41', label: 'Switzerland (+41)' },
    { value: '+46', label: 'Sweden (+46)' },
    { value: '+47', label: 'Norway (+47)' },
    { value: '+358', label: 'Finland (+358)' },
    { value: '+45', label: 'Denmark (+45)' },


    // Add more country options as needed
  ];

 const [submitMessage,setSubmitMessage] = useState(null);
 
//https://admin.maxwayinfotech.in/AddUserDetails
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://admin.maxwayinfotech.in/AddUserDetails', formData);
      if(res.status==200){
        setSubmitMessage('Thank you for your message. It has been sent');
        setTimeout(() => {
          HidePopup();
          if(type=='Home'){
            navigate('game-development');
          }
          
        }, 2000);

      }
      setFormData(initialState);
      // navigate('/thank-you');
     // Reset form data after submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '93%',
      borderRadius: '11px',
      // margin: 'auto',
    }),
  };




 

  return (
    <div className="windoShow">
    <div className="WindowShowP" style={{ maxHeight: "100vh", overflow: 'auto',overflowX:"hidden", position: 'relative', top: '40%', transform: 'translateY(-50%)',position:"relative",width:'100%',margin:'0 13px',maxWidth:'500px',borderRadius:"7px",boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px' }}>
      <div className="window-page">
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="Name">Your Name <GoNorthStar style={{ fontSize: '12px', color: 'red' }}/></label>
            <br />
            <input type="text" name="Name" id="Name" onChange={handleChange} value={formData.Name} required/>
          </div>
          <div>
            <label>Country Code: <GoNorthStar style={{ fontSize: '12px', color: 'red' }}/></label>
            <Select
              name='CountryCode'
              value={countryOptions.find(option => option.value === formData.CountryCode)}
              onChange={handleChange}
              options={countryOptions}
              styles={customStyles}
            />
          </div>
          <div>
            <label htmlFor="Phone">Your Mobile Number <GoNorthStar style={{ fontSize: '12px', color: 'red' }}/></label>
            <br />
            <input type="text" value={formData.Phone} onChange={handleChange} name="Phone" id="Phone" required/>
          </div>
          <div>
            <label htmlFor="WPhone">Your Skype/Whatsapp Number <GoNorthStar style={{ fontSize: '12px', color: 'red' }}/></label>
            <br />
            <input type="text" value={formData.WPhone} onChange={handleChange} name="WPhone" id="WPhone" required/>
          </div>
          <div>
            <label htmlFor="Email">Your Email</label>
            <br />
            <input type="text" name="Email" id="Email" onChange={handleChange} value={formData.Email}/>
          </div>
          <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",marginTop:'30px',marginBottom:'30px'}}>

              <button style={{padding:'0',margin:"0",width:'140px',height:'50px',borderRadius:'5px'}}   type="submit">
                Submit
              </button>
              
            </div>
            <div style={{textAlign:"center",border:"1.5px solid green",display:`${submitMessage?'block':'none'}`,margin:'17px 0'}}>{submitMessage}</div>
            <div onClick={HidePopup} style={{width:'120px',textAlign:'center',
            fontSize:'20px',borderRadius:'20px',position:'absolute',bottom:'0%',right:"-5%"}}>
              Close
            </div>
        </form>
      </div>
    </div>
  </div>
  );
};

export default PageLoading;
