import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
const GotoTop = () => {
    useEffect(() => {
        AOS.init();

    },[])
    let goTOtop= (()=> {
        window.scrollTo({top:0,left:0, behavior:'smooth'});

    })
  return (
    <>
        <div className='top-btn' onClick={goTOtop} data-aos="flip-right" >
            <button><i className="fa-solid fa-arrow-up-from-bracket"></i></button>
        </div>
    </>
  )
}

export default GotoTop