import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import axios from "axios";
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const initialState  = {
    Name:'',
    Email:'',
    CountryCode:'',
    Phone:'',
    WPhone : '',
    Catagory:'',
  }
  
    const [formData , setFormData] = useState(initialState)
    const navigate = useNavigate();

    function handleChange(e){
      if(e.target) {
        setFormData({...formData,[e.target.name] : e.target.value})
      } else {
        setFormData({...formData, CountryCode: e.value})
      }
    }
  
    const countryOptions = [
      { value: '+1', label: 'United States (+1)' },
      { value: '+91', label: 'India (+91)' },
      { value: '+44', label: 'United Kingdom (+44)' },
      { value: '+33', label: ' France (+33)' },
      { value: '+49', label: 'Germany (+49)' },
      { value: '+81', label: ' Japan (+81)' },
      { value: '+1', label: 'Canada (+1)' },
      { value: '+61', label: 'Australia (+61)' },
      { value: '+39', label: 'Italy (+39)' },
      { value: '+34', label: 'Spain (+34)' },
      { value: '+86', label: 'China (+86)' },
      { value: '+55', label: 'Brazil (+55)' },
      { value: '+52', label: 'Mexico (+52)' },
      { value: '+7', label: 'Russia (+7)' },
      { value: '+966', label: 'Saudi Arabia (+966)' },
      { value: '+27', label: 'South Africa (+27)' },
      { value: '+971', label: 'United Arab Emirates (+971)' },
      { value: '+65', label: 'Singapore (+65)' },
      { value: '+82', label: 'South Korea (+82)' },
      { value: '+62', label: 'Indonesia (+62)' },
      { value: '+31', label: 'Netherlands (+31)' },
      { value: '+41', label: 'Switzerland (+41)' },
      { value: '+46', label: 'Sweden (+46)' },
      { value: '+47', label: 'Norway (+47)' },
      { value: '+358', label: 'Finland (+358)' },
      { value: '+45', label: 'Denmark (+45)' },
    ];

    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(formData,'data')
       const res = await axios.post('https://admin.maxwayinfotech.in/AddUserDetails',formData);
      console.log(res);
      setFormData(initialState);
      navigate('/thank-you');
    };


  return (
    <>
      <Header />
      <WindowTop />
      <GotoTop />
      <section>
        <h1 className="text-center my-5">Contact Us</h1>
        <div className="container-fluid my-5">
          <div className="container contact-page">
            <div className="row">
              <div className="col-sm-12 col-xl-4">
                <h2 className="my-4">Get In Touch</h2>
                <p>
                  A Bunch of digital marketing tools for business automation to
                  grow your businesses. We are right by your side, carrying a
                  large variety of technologically advanced, user-friendly, and
                  ready-to-use software for your organization.
                </p>

                <div className="row pb-2">
                  <div className="col-2">
                    <h4 className="fs-2 ">
                      <i className="fa-solid fa-location-dot"></i>
                    </h4>
                  </div>
                  <div className="col-10">
                    <h6>Visit Us:</h6>
                    <span className="fs-6">
                      Near Amrapali Cercle, Vaishali Nagar, Jaipur, Rajasthan
                      302021
                    </span>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-2">
                    <h4 className="fs-2 text-primary ">
                      <i className="fa-solid fa-envelope"></i>
                    </h4>
                  </div>
                  <div className="col-10">
                    <h6>Mail Us:</h6>
                    <span className="fs-6">maxwayinfotech1@gmail.com</span>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-2">
                    <h4 className="fs-2 text-info">
                      <i className="fa-solid fa-phone-volume"></i>
                    </h4>
                  </div>
                  <div className="col-10">
                    <h6>Phone Us:</h6>
                    <span className="fs-6">+91 9116672785</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-xl-4">
                <form action="" className="shadow px-4" onSubmit={handleSubmit}>
                  <div>
                    <label for="name">Name*</label>
                    <br />
                    <input type="text" name='Name' onChange={handleChange} required/>
                  </div>
                  <div>
                    <label for="Email" >Email*</label>
                    <br />
                    <input type="email" name = 'Email'onChange={handleChange} required/>
                  </div>
                  <div>
                  <label>Country Code:</label>
                  <Select
          name='CountryCode'
          value={countryOptions.find(option => option.value === formData.CountryCode)}
          onChange={handleChange}
          options={countryOptions}
        />
        </div>
                  <div>
                    <label for="Mobile">Mobile*</label>
                    <br />
                    <input type="text" name ='Phone' onChange={handleChange} required/>
                  </div>
                  <div>
                    <label for="Mobile">Skype/Whatsapp Mobile No.*</label>
                    <br />
                    <input type="text" name ='WPhone' onChange={handleChange} required/>
                  </div>
                  <div>
                    <label for="Service">Service*</label>
                    <br />
                    <select name="Catagory" id="" onChange={handleChange}>
                      <option value="select">Game development</option>
                      <option value="Fintech Softwere">Fintech Softwere</option>
                      <option value="Web & App Development">
                        Web & App Development
                      </option>
                      <option value="Bulk Whatsapp">Bulk Whatsapp</option>
                      <option value="Digital Markketing">
                        Digital Markketing
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label for="Message">Message*</label>
                    <br />
                    <textarea name="" id="" cols="10" rows="20" style={{height:'30vh'}}></textarea>
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary">
                      Submit Now
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-sm-12 col-xl-4">
                <ul>
                  <li className="cat-item cat-item-501">
                    <a href="https://www.maxwayinfotech.com/category/3d-game-development-in-ahmedabad/">
                      3D GAME DEVELOPMENT IN AHMEDABAD
                    </a>{" "}
                    <span className="post_count">11</span>
                  </li>
                  <li className="cat-item cat-item-504">
                    <a href="https://www.maxwayinfotech.com/category/android-game-development-in-delhi/">
                      ANDROID GAME DEVELOPMENT IN DELHI
                    </a>{" "}
                    <span className="post_count">12</span>
                  </li>
                  <li className="cat-item cat-item-502">
                    <a href="https://www.maxwayinfotech.com/category/aviator-game-development-in-bangalore/">
                      Aviator Game Development in Bangalore
                    </a>{" "}
                    <span className="post_count">11</span>
                  </li>
                  <li className="cat-item cat-item-498">
                    <a href="https://www.maxwayinfotech.com/category/best-game-development-in-gurgaon/">
                      BEST GAME DEVELOPMENT IN GURGAON
                    </a>{" "}
                    <span className="post_count">4</span>
                  </li>
                  <li className="cat-item cat-item-497">
                    <a href="https://www.maxwayinfotech.com/category/best-game-development-in-punjab/">
                      BEST GAME DEVELOPMENT IN PUNJAB
                    </a>{" "}
                    <span className="post_count">2</span>
                  </li>
                  <li className="cat-item cat-item-262">
                    <a href="https://www.maxwayinfotech.com/category/color-prediction-website-developer/">
                      Color Prediction Website Developer
                    </a>{" "}
                    <span className="post_count">11</span>
                  </li>
                  <li className="cat-item cat-item-216">
                    <a href="https://www.maxwayinfotech.com/category/cricket-website-developers/">
                      Cricket Website Developers
                    </a>{" "}
                    <span className="post_count">13</span>
                  </li>
                  <li className="cat-item cat-item-500">
                    <a href="https://www.maxwayinfotech.com/category/custom-game-development-in-madhya-pradesh/">
                      CUSTOM GAME DEVELOPMENT IN MADHYA PRADESH
                    </a>{" "}
                    <span className="post_count">12</span>
                  </li>
                  <li className="cat-item cat-item-243">
                    <a href="https://www.maxwayinfotech.com/category/fantasy-cricket-app-developers/">
                      Fantasy Cricket App Developers
                    </a>{" "}
                    <span className="post_count">13</span>
                  </li>
                  <li className="cat-item cat-item-308">
                    <a href="https://www.maxwayinfotech.com/category/fantasy-sports-website-development/">
                      fantasy sports website development
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-185">
                    <a href="https://www.maxwayinfotech.com/category/game-application-developer/">
                      Game Application Developer
                    </a>{" "}
                    <span className="post_count">38</span>
                  </li>
                  <li className="cat-item cat-item-180">
                    <a href="https://www.maxwayinfotech.com/category/game-developer/">
                      Game Developer
                    </a>{" "}
                    <span className="post_count">111</span>
                  </li>
                  <li className="cat-item cat-item-134">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-ahmedabad/">
                      Game developer Ahmedabad
                    </a>{" "}
                    <span className="post_count">2</span>
                  </li>
                  <li className="cat-item cat-item-139">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-benglor/">
                      Game Developer Benglor
                    </a>{" "}
                    <span className="post_count">3</span>
                  </li>
                  <li className="cat-item cat-item-127">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-delhi/">
                      Game Developer Delhi
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-136">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-jaipur/">
                      Game Developer Jaipur
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-138">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-kolkata/">
                      Game developer Kolkata
                    </a>{" "}
                    <span className="post_count">3</span>
                  </li>
                  <li className="cat-item cat-item-140">
                    <a href="https://www.maxwayinfotech.com/category/game-developer-noida/">
                      Game Developer Noida
                    </a>{" "}
                    <span className="post_count">2</span>
                  </li>
                  <li className="cat-item cat-item-499">
                    <a href="https://www.maxwayinfotech.com/category/game-development-company-in-india/">
                      Game Development Company in India
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-76">
                    <a href="https://www.maxwayinfotech.com/category/gaming-development/">
                      Game Development
                    </a>{" "}
                    <span className="post_count">96</span>
                  </li>
                  <li className="cat-item cat-item-291">
                    <a href="https://www.maxwayinfotech.com/category/ios-game-developer-in-jaipur/">
                      IOS GAME DEVELOPER IN JAIPUR
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-303">
                    <a href="https://www.maxwayinfotech.com/category/ludo-game-developers/">
                      Ludo Game Developers
                    </a>{" "}
                    <span className="post_count">15</span>
                  </li>
                  <li className="cat-item cat-item-213">
                    <a href="https://www.maxwayinfotech.com/category/mobile-game-developer/">
                      Mobile Game Developer
                    </a>{" "}
                    <span className="post_count">45</span>
                  </li>
                  <li className="cat-item cat-item-496">
                    <a href="https://www.maxwayinfotech.com/category/poker-game-development-company/">
                      POKER GAME DEVELOPMENT COMPANY
                    </a>{" "}
                    <span className="post_count">5</span>
                  </li>
                  <li className="cat-item cat-item-17">
                    <a href="https://www.maxwayinfotech.com/category/ppc/">
                      PPC
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-503">
                    <a href="https://www.maxwayinfotech.com/category/real-money-game-development-in-maharashtra/">
                      REAL MONEY GAME DEVELOPMENT IN MAHARASHTRA
                    </a>{" "}
                    <span className="post_count">12</span>
                  </li>
                  <li className="cat-item cat-item-18">
                    <a href="https://www.maxwayinfotech.com/category/seo/">
                      SEO
                    </a>{" "}
                    <span className="post_count">2</span>
                  </li>
                  <li className="cat-item cat-item-19">
                    <a href="https://www.maxwayinfotech.com/category/smm/">
                      SMM
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-494">
                    <a href="https://www.maxwayinfotech.com/category/teen-patti-game-development-service/">
                      TEEN PATTI GAME DEVELOPMENT SERVICE
                    </a>{" "}
                    <span className="post_count">28</span>
                  </li>
                  <li className="cat-item cat-item-495">
                    <a href="https://www.maxwayinfotech.com/category/top-game-development-companies/">
                      Top Game Development Companies
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-1">
                    <a href="https://www.maxwayinfotech.com/category/uncategorized/">
                      Uncategorized
                    </a>{" "}
                    <span className="post_count">25</span>
                  </li>
                  <li className="cat-item cat-item-471">
                    <a href="https://www.maxwayinfotech.com/category/unity-game-development-internship-in-jaipur/">
                      Unity Game Development Internship in Jaipur
                    </a>{" "}
                    <span className="post_count">2</span>
                  </li>
                  <li className="cat-item cat-item-475">
                    <a href="https://www.maxwayinfotech.com/category/unity-game-development-internship-in-rajasthan/">
                      unity game development internship in rajasthan
                    </a>{" "}
                    <span className="post_count">3</span>
                  </li>
                  <li className="cat-item cat-item-84">
                    <a href="https://www.maxwayinfotech.com/category/website-development/">
                      Website Development
                    </a>{" "}
                    <span className="post_count">1</span>
                  </li>
                  <li className="cat-item cat-item-88">
                    <a href="https://www.maxwayinfotech.com/category/whatsapp-marketing/">
                      Whatsapp Marketing
                    </a>{" "}
                    <span className="post_count">4</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <WhatsappFixed/>
    </>
  );
};

export default Contact;
