import React from "react";
import logo1 from "../image/logoWeb.webp";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { PiPhoneFill } from "react-icons/pi";
import 'animate.css';



const Header = () => {
  
  function active() {
    let divActive = document.querySelector(".navbar-center");
    let icon = document.querySelector(".icon");
    let activeBar= document.querySelector('.activeBar');
    
    divActive.classList.toggle("activeraw");
    icon.classList.toggle("fa-xmark");
    activeBar.classList.toggle('dactive');    
  } 
  return (
    <>
      <div className="container-fluid header">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-sm-4 col-md-4 ps-5 border-end">
              <i className="fa-brands fa-facebook"></i>
              <i className="fa-brands fa-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
              <i className="fa-brands fa-instagram"></i>
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4 ps-5 border-end">
              <Link to="mail to:maxwayinfotech1@gmail.com">
              <MdEmail style={{fontSize:'20px '}}/> maxwayinfotech1@gmail.com
              </Link>
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4 ps-5 ">
              <span>
                <PiPhoneFill style={{fontSize:'20px'}}/>
              </span>
              <Link to="tele to:9116672785">+91 9116672785</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid navbar-header animate__animated animate__zoomInDown" >
        <div className="container">
          <div className="navbar">
            <div className="navbar-logo">
              <img src={logo1} alt="" />
            </div>
            <div className="navbar-icon"></div>
            <div className="navbar-pop">
              <span>
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
              <i className="fa-solid fa-bars icon" onClick={active}></i>
            </div>
            <div className="navbar-center" style={{fontSize:'17px'}}>
              <ul>
                <li><Link className="hLink" to="/">Home</Link></li>
                <li><Link className="hLink" to="/About">About Us</Link>  </li>
                <span className="activeBar" ><i className="fa-solid fa-right-from-bracket"></i></span>
                <li className="liHover gaming" style={{position:'relative'}}>
                 Game Development
                  <i className="fa-solid fa-plus icons poppop"></i>
                  <div className="Popup" style={{position:'absolute',top:'65px',left:'120px',transform:'translateX(-45%)'}}>
                    <ul>
                      <li style={{padding:'10px',margin:'15px'}}><Link className="hLink" to="/game-development" style={{color:'black'}}>Game App Development</Link> </li>
                      <li style={{padding:'10px',margin:'15px'}}> <Link className="hLink" to="/fantesy" style={{color:'black'}}>Fantasy Game Development</Link></li>
                      <li style={{padding:'10px',margin:'15px'}}> <Link className="hLink" to="/color" style={{color:'black'}}>Color Prediction Games</Link></li>
                    </ul>
                  </div>
                </li>
                <li className="liHover service"  style={{position:'relative'}}>
                    Service <i className="fa-solid fa-plus icons"></i>
                  <div className="Popup"  style={{position:'absolute',top:'65px',left:'120px',transform:'translateX(-45%)'}}>
                    <ul>
                      <li> <Link className="hLink" to="/website" style={{color:'black'}}>Website Design & Development</Link> </li>
                      <li> <Link className="hLink" to="/android"  style={{color:'black'}}>Android App Development</Link> </li>
                      <li> <Link className="hLink" to="/digital"  style={{color:'black'}}>Digital Marketing</Link></li>
                      <li> <Link className="hLink" to="/whatsapp"  style={{color:'black'}}>Bulk Whatsapp</Link> </li>
                      <li> <Link className="hLink" to="/sms"  style={{color:'black'}}>Bulk SmS</Link> </li>
                    </ul>
                  </div>
                </li>
                <li> <Link className="hLink" to="/finetech">Fintech Softwere</Link> </li>
                <li><Link className="hLink" to="/blog">Blog</Link></li>
              </ul>
            </div>
            <div className="navbar-right">
              <span>
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
              <button  className=""><Link className="hLink"  to="/contact" style={{color:'white',fontSize:"17px"}}>Contact Us</Link></button>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-xl-6 mx-auto">
           
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default Header;
