import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import digitalImg from "../image/Screenshot-2023-09-28-103307.webp";
import digitalImg1 from "../image/3545757.webp";
import digitalImg2 from "../image/Screenshot-2023-09-28-114102.webp";
import GotoTop from "./GotoTop";
import WindowTop from "./WindowTop";
import WhatsappFixed from "./WhatsappFixed";
import PageLoading from "./PageLoading";

const DigitalMarketing = () => {
  return (
    <>
      <WhatsappFixed />
      <WindowTop />
      <GotoTop />
      <Header />
      <PageLoading type='digital marketing' />

      <section>
        <h1 className="text-center my-5">Digital Marketing</h1>
        <div className="container-fluid digital-page">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <h2 className="fs-1">Maxway Digital marketing solution</h2>
                <p>
                  One of the best IT company in Jodhpuroffers Software
                  development, web development, and application development
                  services &amp; solutions.
                </p>
                <h3>Digital Marketing</h3>
                <p>
                  With the evolution of computers and smartphones, the real
                  meaning of marketing has changed. Earlier it was considered a
                  source of actual customers. But with the emergence of digital
                  devices, digital marketing has come into picture where both
                  actual and potential customers are available. Shiva
                  Technotonics addresses these potential customers into actual
                  customers by representing you with digital marketing
                  solutions.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={digitalImg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid digital-page1">
          <div className="container py-5">
            <h3 className="fs-2">Search Engine Optimization</h3>
            <p>
              Now millions of websites are registered on the domain name server.
              The search engine optimization optimizes your web product that
              increases your visibility across all search engines like Google,
              Yahoo, etc. SEO will increase internet traffic and you will get
              superior results in terms of business. During SEO we focus on
              keywords, those optimize your web product. In addition to this we
              moderate your web contents as per the search criteria.
            </p>
            <h3 className="fs-2">Social Media Optimization</h3>
            <p>
              There are numerous social media user worldwide. The social media
              optimization target these users by optimizing your product on
              social media platforms. This optimization of your product will
              attract huge number of people and hence your product will attract
              clients. The social media optimization is the best way to hit the
              mass audience in affordable prices.
            </p>
            <p>
              <span>Maxway Infotech</span> is the foremost company in Jaipur,
              dealing in multiple domains including Software Development, Web
              Development, Business Promotions, Research &amp; Development, and
              others. Specialized development, exclusive solutions constant
              technical support differentiate us with the other companies.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid digital-page">
          <div className="container">
            <div className="row py-5">
              <div className="col-sm-12 col-xl-6">
                <img src={digitalImg1} alt="" className="img-fluid" />
              </div>
              <div className="col-sm-12 col-xl-6">
                <h2 className="weight700">~ We Give Result Of Your Money ~</h2>
                <p>
                  We are providing all types of digital works and promotions on
                  all platforms related to Digital Marketing .
                </p>
                <p>
                  <strong>We provide the following services:</strong> –<br />
                  -Social Media Marketing
                  <br />
                  -Facebook, Instagram, Twitter, LinkedIn ads
                  <br />
                  -Facebook Page Likes and Followers Increase
                  <br />
                  -Google SEO, PPC
                  <br />
                  -Google Reviews Increase
                  <br />
                  -Bulk WhatsApp Marketing National and International Complete
                  Solution
                  <br />
                  -Bulk SMS
                  <br />
                  -Data Extractors Software (Google, Just Dail, LinkedIn Etc.
                  Categories Wise Data For Your business Promotion )<br />
                  -Animation videos
                  <br />
                  -Website Design and Development (With SEO Friendly)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container-fluid digital-page1">
          <div className="container">
            <h2 className="text-center fs-1 my-5">
              Custom Logo Design for Your Brand Visibility
            </h2>
            <div className="row">
              <div className="col-sm-12 col-xl-6">
                <p>
                  We are a professional logo design company in Jaipur having
                  professionals that are expert in corporate and business logos.
                  We know that logo of a company will remain its identity even
                  after company’s existence.
                </p>
                <p>
                  Any company cannot hire such a logo maker who will ruin an
                  important part of a company. We offer the best design and
                  branding services for companies of all sizes (small, medium
                  and large scale firms).
                </p>
                <p>
                  <span>Maxway Infotech </span>have talented and creative
                  graphic designers who can deliver expected logos according to
                  customer’s need. We have years of experience in logo designing
                  and already designed logos for a variety of sectors.
                </p>
              </div>
              <div className="col-sm-12 col-xl-6">
                <img src={digitalImg2} alt="" className="img-fluid" />
              </div>
              <div className="col-12 py-1">
                <p>
                  Our designers ensure high quality of logo with unique design
                  and compatibility. We assure you that you will never find a
                  company better than our logo design company in India.
                </p>
                <p>
                  A logo represents the company’s brand name to the customers.
                  Logo defines what the users perceive of your brand. It is the
                  first thing that is visible on the top of a website.
                </p>
                <p>
                  If a logo is not attractive, there are high chances that
                  customer will not remember the company. We make sure that
                  every client gets to see a version of their company in their
                  logo that we design for them.
                </p>
                <p>
                  Our main motive in logo designing is incorporating of client’s
                  thought and services in the logo. Having a good logo helps
                  customers to reach out to the company and can relate their
                  services to it. Logo designed at <span>Maxway Infotech </span>
                  provides uniqueness to your brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DigitalMarketing;
